import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import TableHeader from '../../components/dbDisplay/aux-products/surfactants/surfactantsHeaderRow';
import TableRow from '../../components/dbDisplay/aux-products/surfactants/surfactantsRow';

import { useHistory } from 'react-router-dom';

export default function Surfactants(props) {
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const [surfactants, setSurfactants] = useState({});

	useEffect(() => {
		const ref = firebase.database().ref('aux-products/surfactants');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					newState.push({
						id: item,
						RKIPartNo: items[item].RKIPartNo,
						name: items[item].name,
						price_bottle: items[item].price_bottle,
						price_jug: items[item].price_jug,
						price_5_gal_pail: items[item].price_5_gal_pail,
						price_55_gal_drum: items[item].price_55_gal_drum,
					});
				}

				setSurfactants(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};
	
	return (
		<div className="container">
			<Helmet>
				<title>Koch Color - Surfactants</title>
				<meta
					name="description"
					content="The word surfactant is a contraction of three words, Surface Active
						Agent. As the name implies, a surfactant acts on the surface or
						interface between two different substances. They reduce the surface
						tension that exists between a liquid and a gas, two different
						liquids (i.e oil and water), or a liquid and a solid. Surfactants
						can perform different functions depending on their chemical
						structure. They serve as wetting agents, detergents, dispersants,
						emulsifiers and foaming agents."
				/>
			</Helmet>
			<div className="section box ob-section">
				<div className="title has-text-centered ">Surfactants</div>
				<div className="block is-size-4">Description, Applications & Use</div>
				<div className="block">
					<p className="block">
						The word surfactant is a contraction of three words, Surface Active
						Agent. As the name implies, a surfactant acts on the surface or
						interface between two different substances. They reduce the surface
						tension that exists between a liquid and a gas, two different
						liquids (i.e oil and water), or a liquid and a solid. Surfactants
						can perform different functions depending on their chemical
						structure. They serve as wetting agents, detergents, dispersants,
						emulsifiers and foaming agents.
					</p>
					<p className="block">
						We offer two specialty surfactants for use with our dyes and
						fragrance oils.
					</p>
				</div>
				<div className="block is-size-4">TW-80</div>
				<div className="block">
					<p className="block">
						TW-80 makes a good wetting agent for use in immersion dyeing
						applications. It helps wet the surface of the material being dyed
						(textiles, natural fibers, wood etc.) and helps dye molecules
						penetrate the surface and migrate into the fibers more evenly. This
						aids in producing a more uniform (level) dyeing. The amount needed
						is small. A use rate of 0.1% w/w (1 mg/l, ¾ tsp per gal) is usually
						sufficient.
					</p>
				</div>
				<div className="block is-size-4">TW-20</div>
				<div className="block">
					<p className="block">
						TW-20 is a good emulsifier and can be used to add fragrance oils to
						water-based products. Surfactant molecules are linear, having a
						water loving (hydrophilic) head and an oil loving (lipophilic) tail.
						The lipophilic end of surfactant molecules surround (encapsulate)
						droplets of fragrance oil forming what is called a micelle. The
						hydrophilic (polar) ends form the outer surface of the micelles,
						enabling them to remain suspended (emulsified) in the water phase.
						The amount of surfactant needed to emulsify a fragrance oil can vary
						widely from 1:1 surfactant to fragrance up to 5:1 surfactant to
						fragrance by weight. The higher the amount of fragrance used the
						clearer and more stable the emulsion will be. Occasionally the
						phrase “making a fragrance water soluble” is used, but the correct
						terminology is emulsifying a fragrance.
					</p>
					<p className="block">
						TW-20 can also be used as a wetting agent in the systemic dyeing and
						preserving of fresh cut flowers and foliage. TW-20 helps the dye
						molecules move through the vascular network of the stem, leaves and
						petals. The general use rate is 1 mg/l (3/4 tsp per gallon) of dye
						solution.
					</p>
				</div>
			</div>

			<div className="columns">
				<div className="column">
					<div className="box">
						<section className="">
							<TableHeader />
							{surfactants && surfactants.length > 0 ? (
								surfactants.map((item) => {
									if (!searchId) {
										return <TableRow {...item} key={item.id} />;
									} else {
										if (item.id === searchId) {
											return (
												<>
													<TableRow {...item} key={item.id} />
													<button
														className="button is-info is-small"
														onClick={onSearchClearClicked}
													>
														Clear Search Results
													</button>
												</>
											);
										}
										return null;
									}
								})
							) : (
								<p>This table does is empty or does not exist</p>
							)}
						</section>
					</div>
				</div>
			</div>
		</div>
	);
}
