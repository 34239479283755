import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import TableHeader from '../../components/dbDisplay/aux-products/glycols/glycolHeaderRow';
import TableRow from '../../components/dbDisplay/aux-products/glycols/glycolRow';
import { useHistory } from 'react-router-dom';

export default function Glycols(props) {
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const [glycols, setGlycols] = useState({});

	useEffect(() => {
		const ref = firebase.database().ref('aux-products/glycols');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					newState.push({
						id: item,
						RKIPartNo: items[item].RKIPartNo,
						name: items[item].name,
						price_gal_jug: items[item].price_gal_jug,
						price_5_gal_pail: items[item].price_5_gal_pail,
						price_55_gal_drum: items[item].price_55_gal_drum,
					});
				}

				setGlycols(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<div className="container">
			<Helmet>
				<title>Koch Color - Glycerin</title>
				<meta
					name="description"
					content="Glycerin is a modified alcohol that is hydrophilic (water loving), has a high boiling point and low volatility.
One of many interesting applications for glycerin is its use in preserving fresh cut foliage. Due to its low volatility, it evaporates extremely slowly at normal temperature. Being hydrophilic, it has humectant properties and helps retain moisture in products in which it is incorporated. This is one reason it is often found in skin moisturizers. By replacing the volatile (evaporative) water in plant tissue with a non-evaporative liquid like glycerin, certain foliages can be preserved; remaining soft, flexible and supple for several years. Glycerin is water-soluble and has a relatively low molecular weight enabling it to be taken up systemically (through the stem) in fresh cut foliage and flowers. Detailed information about this process can be found in the following publications."
				/>
			</Helmet>
			<div className="section box ob-section">
				<div className="title has-text-centered ">Glycerin</div>
				<div className="block is-size-4">Description & Use</div>
				<div className="block">
					<p className="block">
						Glycerin is a modified alcohol that is hydrophilic (water loving),
						has a high boiling point and low volatility. One of many interesting
						applications for glycerin is its use in preserving fresh cut
						foliage. Due to its low volatility, it evaporates extremely slowly
						at normal temperature. Being hydrophilic, it has humectant
						properties and helps retain moisture in products in which it is
						incorporated. This is one reason it is often found in skin
						moisturizers. By replacing the volatile (evaporative) water in plant
						tissue with a non-evaporative liquid like glycerin, certain foliages
						can be preserved; remaining soft, flexible and supple for several
						years. Glycerin is water-soluble and has a relatively low molecular
						weight enabling it to be taken up systemically (through the stem) in
						fresh cut foliage and flowers. Detailed information about this
						process can be found in the following publications.
					</p>
				</div>
				{/* <div className="block is-size-4">Glycerin & Propylene Glycol</div> */}
				<div className="block">
					{/* <p className="block">
						One of many interesting applications for Glycerin and Propylene
						Glycol (PG) is their use in preserving fresh cut foliage. Glycols,
						due to their low volatility, evaporate extremely slowly at normal
						temperature. Being hydrophilic, they have humectant properties and
						help retain moisture in products in which they are incorporated.
						This is one reason why they are often found in skin moisturizers. By
						replacing the volatile (evaporative) water in plant tissue with a
						non-evaporative liquid like glycerin or PG, certain foliages can be
						preserved; remaining soft, flexible and supple for several years.
						Both glycerin and PG are water-soluble and have a relatively low
						molecular weight enabling them to be taken up systemically (through
						the stem) in fresh cut foliage and flowers. Detailed information
						about this process can be found in the following publications:
					</p> */}

					<div className="block has-background-primary has-text-white p-2">
						<p className="pb-3">
							<span className="has-text-weight-bold">
								Preserving and Coloring Foliage with Glycols and Dyes
							</span>{' '}
							-<span className="highlight-orange"> Publication 420</span> - 48
							pages ($40)
						</p>
						<p className="pb-3">
							<a
								className="button is-info"
								href="../PDF Collection/technical-bulletins/preserving-foliage-with-glycerin-ref-420v8-WEB.pdf"
								target="blank"
							>
								A sample of Publication 420 can be viewed here.
							</a>
						</p>
						<p className="pb-3">
							<span className="has-text-weight-bold">
								Preserving and Coloring Foliage with Glycols and Dyes
							</span>{' '}
							- A Manual for the
							<span className="highlight-orange"> Commercial Producer</span> –
							200 pages ($98).
						</p>
						<p className="pb-3">
							<a
								className="button is-info"
								href="../PDF Collection/technical-bulletins/Publication 421-WEB.pdf"
								target="blank"
							>
								A sample of Publication 421 can be viewed here.
							</a>
						</p>
						To order, email
						<a href="mailto:sales@kochcolor.com" className="highlight-orange">
							{' '}
							sales@kochcolor.com
						</a>{' '}
						or call <span className="highlight-orange">303.644.3763</span>.
					</div>
				</div>
				{/* <div className="block is-size-4">Dipropylene Glycol</div> */}
				{/* <div className="block">
					<p className="block">
						Dipropylene Glycol (DPG) is a glycol that can be used to dilute
						fragrance oils. In their pure form, fragrance oils are very
						concentrated and may be too strong for certain applications.
						Lowering the strength can be achieved by diluting the oil with DPG.
						We offer a fragrance grade version that meets the requirements of
						the International Fragrance Association (IFRA).
					</p>
				</div> */}
			</div>

			<div className="columns">
				<div className="column">
					<div className="box">
						<section className="">
							<TableHeader />
							{glycols && glycols.length > 0 ? (
								glycols.map((item) => {
									if (!searchId) {
										return <TableRow {...item} key={item.id} />;
									} else {
										if (item.id === searchId) {
											return (
												<>
													<TableRow {...item} key={item.id} />
													<button
														className="button is-info is-small"
														onClick={onSearchClearClicked}
													>
														Clear Search Results
													</button>
												</>
											);
										}
										return null;
									}
								})
							) : (
								<p>This table does is empty or does not exist</p>
							)}
						</section>
					</div>
				</div>
			</div>
		</div>
	);
}
