import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import ConversionButton from '../../components/dbDisplay/dyes/conversionButton';

import GeneralTable from '../../components/dbDisplay/dyes/generalTable';

import wash_img from '../../images/dyes/wash/windshield-washer-fluid.png';

import pink_coolant_img from '../../images/dyes/engine/pink_coolant.webp';
import washfluid_img from '../../images/dyes/wash/washerfluid-3b.jpg';
import { useHistory } from 'react-router-dom';
import * as RES from '../../helpers/responsive';

export default function Wash(props) {
	const [dyes, setDyes] = useState({});
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const pdf_paths = {
		tds: '/PDF Collection/2000-TDS/',
		sds: '/PDF Collection/SDS Files/2000-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/general');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					if (items[item].wash) {
						newState.push({
							id: item,
							sort_order: items[item].sort_order,
							partNo: items[item].RKIPartNo,
							name: items[item].name,
							descriptor: items[item].descriptor ? items[item].descriptor : '',
							physicalType: items[item].physicalType,
							lightShade: items[item].lightShade,
							darkShade: items[item].darkShade,
							price_1lb_Jar: items[item].price_1lb_Jar,
							price_5lb_Jar: items[item].price_5lb_Jar,
							price_25gal_Pail: items[item].price_25gal_Pail,
							price_small_drum: items[item].price_small_drum,
							price_large_drum: items[item].price_large_drum,
							price_tote: items[item].price_tote ? items[item].price_tote : 0,
							primaryColor: items[item].primaryColor,
							primaryColorOrder: items[item].primColorOrder,
							formerTradeNames: items[item].formerTradeNames,
							fluorescent: items[item].fluorescent
								? items[item].fluorescent
								: false,
							has_link: items[item].has_link ? items[item].has_link : false,
							link_url: items[item].link_url ? items[item].link_url : '',
							has_sds: items[item].has_sds ? items[item].has_sds : false,
							sds_filename: items[item].sds_filename
								? items[item].sds_filename
								: false,
							inactive: items[item].inactive ? items[item].inactive : false,
						});
					}
				}
				newState.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
				setDyes(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>
					Koch Color - Dyes for Windshield Washer Fluid & Glass Cleaner
				</title>
				<meta
					name="description"
					content="Like many products, there are color standards for glass cleaners and windshield washer fluid. While blue dominates the market, orange and occasionally purple are used. These colors are available in both powder and liquid form. Liquid dyes offer ease of use, are dust free and generally cleaner to use. Powder dyes offers the advantage of lower freight cost and are not subject to freezing like liquid dyes. Please see the attached technical bulletins for use rate recommendations. The following dyes have a long track record in this application and have not been shown to cause staining when used at or below their recommended use level."
				/>
			</Helmet>
			<div className="section pt-5">
				<RES.Desktop>
					<div className="box">
						<div className="title has-text-centered ">
							Dyes for Windshield Washer Fluid & Glass Cleaner
						</div>
						<div className="subtitle  has-text-centered"></div>

						<ConversionButton />

						<section className="container">
							<div className="columns is-flex is-centered">
								<div className="column is-3">
									<img className="wash" src={wash_img} alt="washer fluid" />
								</div>
								<div className="column is-4">
									<img
										className="wash"
										src={pink_coolant_img}
										alt="washer fluid"
									/>
								</div>
								<div className="column is-3">
									<img
										className="wash"
										src={washfluid_img}
										alt="washer fluid"
									/>
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description & Application</div>
							<div className="block">
								Like many products, there are color standards for glass cleaners
								and windshield washer fluid. While blue dominates the market,
								orange and occasionally purple are used. These colors are
								available in both powder and liquid form. Liquid dyes offer ease
								of use, are dust free and generally cleaner to use. Powder dyes
								offers the advantage of lower freight cost and are not subject
								to freezing like liquid dyes. Please see the documents below for
								washer fluid rate recommendation tables. The dyes listed below
								have a long track record in this application and have not been
								shown to cause staining when used at or below their recommended
								use level.
							</div>
							<div className="block is-size-4">
								Washer Fluid Use Rate Documents
							</div>
							<div className="block">
								<a
									className="button is-success"
									target="_blank"
									rel="noreferrer"
									href={
										process.env.PUBLIC_URL +
										'/PDF Collection/2000-TDS/Washer-Fluid-Rates/2325-liq-windshield-wash-use-table-v2-28-sept-2020.45b356b7.pdf'
									}
								>
									Blue Lace 2325 Liquid Dye
								</a>
							</div>
							<div className="block">
								<a
									className="button is-success"
									target="_blank"
									rel="noreferrer"
									href={
										process.env.PUBLIC_URL +
										'/PDF Collection/2000-TDS/Washer-Fluid-Rates/orange-2252-Liq-washer-fluid-use-tbl-v3b.pdf'
									}
								>
									Orange 2252 Liquid Dye
								</a>
							</div>
						</div>
					</div>
					<GeneralTable
						filteredDyes={dyes}
						// filterColors=
						onFilterClicked={() => {}}
						title="Dyes for Windshield Washer Fluid & Glass Cleaner"
						displayPhysicalForm={true}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						noTDS={false}
					/>
				</RES.Desktop>
				<RES.Handheld>
					<div>
						<GeneralTable
							filteredDyes={dyes}
							// filterColors=
							onFilterClicked={() => {}}
							title="Dyes for Windshield Washer Fluid & Glass Cleaner"
							displayPhysicalForm={true}
							onSearchClear={onSearchClearClicked}
							searchId={searchId}
							pdf_paths={pdf_paths}
							noTDS={false}
						/>
					</div>
				</RES.Handheld>
			</div>
		</>
	);
}
