import React from 'react';
import * as RES from '../../../helpers/responsive';

export default function FragranceHeaderRow(props) {
	return (
		<>
			<RES.Default>
				<div>
					<div className="title has-text-centered has-background-white mb-0 pt-3">
						{props.title}
					</div>
					<div className="columns has-background-white">
						<div className="column is-5">
							<div className="columns">
								<div className="column has-text-centered">
									<br />
								</div>
							</div>
							<div className="columns  is-mobile">
								<div className="column is-3 has-text-weight-bold  is-size-7-mobile">
									Product Code
								</div>
								<div className="column is-6 has-text-weight-bold">
									Product Name
								</div>
								<div className="column is-3 has-text-weight-bold">SDS</div>
							</div>
						</div>

						<div className="column is-7">
							<div className="columns">
								<div className="column has-text-centered has-text-weight-bold">
									Price per pound
								</div>
							</div>
							<div className="columns  is-mobile">
								<div className="column is-3 has-text-weight-bold">
									Quart
									<br />
									(2 lbs)
								</div>
								<div className="column is-3 has-text-weight-bold">
									1 Gallon
									<br />
									(7-8 lbs)
								</div>
								<div className="column is-3 has-text-weight-bold">
									5 Gallons
									<br />
									(30-35 lbs)
								</div>
								<div className="column is-3 has-text-weight-bold">
									50 Gallons
									<br />
									(400 lb drum)
								</div>
							</div>
						</div>
					</div>
					<div className="columns header-bottom-border has-background-link">
						<div className="column"></div>
					</div>
				</div>
			</RES.Default>
			<RES.Mobile>
				<div>
					<div className="columns has-background-primary has-text-white">
						<div className="column is-5">
							<div className="columns">
								<div className="column has-text-centered is-size-4 mt-2 py-0">
									{props.title}
								</div>
							</div>
							<div className="columns is-mobile is-size-7-mobile">
								<div className="column is-3 has-text-weight-bold  my-0 py-0">
									Product Code
								</div>
								<div className="column is-6 has-text-weight-bold  my-0 py-0">
									Product Name
								</div>
								<div className="column is-3 has-text-weight-bold  my-0 py-0">
									SDS
								</div>
							</div>
						</div>

						<div className="column is-7">
							<div className="columns">
								<div className="column has-text-centered has-text-weight-bold is-size-7-mobile my-0 pt-4 pb-0">
									Price per pound
								</div>
							</div>
							<div className="columns is-mobile is-size-7-mobile">
								<div className="column is-3 has-text-weight-bold my-0 py-0">
									Quart
									<br />
									(2 lbs)
								</div>
								<div className="column is-3 has-text-weight-bold my-0 py-0">
									1 Gallon
									<br />
									(7-8 lbs)
								</div>
								<div className="column is-3 has-text-weight-bold my-0 py-0">
									5 Gallons
									<br />
									(30-35 lbs)
								</div>
								<div className="column is-3 has-text-weight-bold my-0 py-0">
									50 Gallons
									<br />
									(400 lb drum)
								</div>
							</div>
						</div>
					</div>
					<div className="columns header-bottom-border has-background-link">
						<div className="column"></div>
					</div>
				</div>
			</RES.Mobile>
		</>
	);
}
