import React from 'react';
import SolventHeaderRow from './SolventHeaderRow';
import SolventRow from './SolventRow';
import Sticky from 'react-stickynode';
import Filter from '../../../containers/dyes/Filters/DyesFilters';
import * as RES from '../../../helpers/responsive';

const SolventTable = (props) => {
	const onFilterClicked = (value) => {
		props.onFilterClicked(value);
	};

	return (
		<>
			<RES.Desktop>
				{' '}
				<div className="is-mobile">
					<div className="box">
						<section className="">
							<Sticky enabled={true} top={110}>
								<div>
									{props.filterColors ? (
										<Filter
											className=""
											onClick={props.filter ? onFilterClicked : null}
											total={props.filter.length}
											filterColors={props.filterColors}
										/>
									) : null}
									<SolventHeaderRow
										title={props.title}
										displayPhysicalForm={props.displayPhysicalForm}
										noLiquid={props.noLiquid}
										noTDS={props.noTDS}
										noPwdGran={props.noPwdGran}
										nps={props.nps}
									/>
								</div>
							</Sticky>
							{props.filter && props.filter.length > 0
								? props.filter.map((item) => {
										if (!props.searchId) {
											if (!item.powder && !item.inactive) {
												return (
													<SolventRow
														{...item}
														key={item.id}
														displayPhysicalForm={props.displayPhysicalForm}
														noTDS={props.noTDS}
														pdf_paths={props.pdf_paths}
														nps={props.nps}
													/>
												);
											} else return null;
										} else {
											if (item.id === props.searchId) {
												return (
													<>
														<SolventRow
															{...item}
															key={item.id}
															displayPhysicalForm={props.displayPhysicalForm}
															noTDS={props.noTDS}
															pdf_paths={props.pdf_paths}
															nps={props.nps}
														/>
														<button
															className="button is-info is-small"
															onClick={() => props.onSearchClear()}
														>
															Clear Search Results
														</button>
													</>
												);
											}
										}
										return null;
								  })
								: null}
							{/* (
						<progress className="progress is-small is-primary" max="100">
							15%
						</progress>
					)} */}
						</section>
					</div>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<>
					<div className="">
						<section className="">
							<Sticky enabled={true} top={110} innerZ="9999">
								<div>
									{/* {props.filterColors ? (
										<Filter
											className=""
											onClick={props.filter ? onFilterClicked : null}
											total={props.filter.length}
											filterColors={props.filterColors}
										/>
									) : null} */}
									<SolventHeaderRow
										title={props.title}
										displayPhysicalForm={props.displayPhysicalForm}
										noLiquid={props.noLiquid}
										noTDS={props.noTDS}
										noPwdGran={props.noPwdGran}
										nps={props.nps}
									/>
								</div>
							</Sticky>
							{props.filter && props.filter.length > 0
								? props.filter.map((item) => {
										if (!props.searchId) {
											if (!item.powder && !item.inactive) {
												return (
													<SolventRow
														{...item}
														key={item.id}
														displayPhysicalForm={props.displayPhysicalForm}
														noTDS={props.noTDS}
														pdf_paths={props.pdf_paths}
														nps={props.nps}
													/>
												);
											} else return null;
										} else {
											if (item.id === props.searchId) {
												return (
													<>
														<SolventRow
															{...item}
															key={item.id}
															displayPhysicalForm={props.displayPhysicalForm}
															noTDS={props.noTDS}
															pdf_paths={props.pdf_paths}
															nps={props.nps}
														/>
														<button
															className="button is-info is-small"
															onClick={() => props.onSearchClear()}
														>
															Clear Search Results
														</button>
													</>
												);
											}
										}
										return null;
								  })
								: null}
							{/* (
						<progress className="progress is-small is-primary" max="100">
							15%
						</progress>
					)} */}
						</section>
					</div>
				</>
			</RES.Handheld>
		</>
	);
};

export default SolventTable;
