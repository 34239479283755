import React, { useState, useEffect, useContext } from 'react';
import { titleCase } from 'title-case';

import tds_icon from '../../../images/tds-icon.png';
import sds_icon from '../../../images/sds-icon1.png';
import './tablerow.scss';
import { LanguageContext } from '../../../context/LanguageContext';
import * as RES from '../../../helpers/responsive';
import infoIcon from '../../../images/icon-info.png';


export default function DyeTableRow(props) {
	const [isLiquid, setIsLiquid] = useState(false);
	const { browserLanguageSpanish } = useContext(LanguageContext);

	useEffect(() => {
		if (props.physicalType) {
			if (titleCase(props.physicalType.toLowerCase()) === 'Liquid') {
				setIsLiquid(true);
			}
		}
	}, [props]);

	const createName = () => {
		const name = [];
		name.push(props.name);
		if (props.descriptor) {
			name.push(<div key='1'>({props.descriptor})</div>);
		}
		return name;
	};

	const formerNames = () => {

		if (!props.formerTradeNames) {
			return <div>{createName()}</div>;
		} else {
			if (props.formerTradeNames) {
			const oldnames = props.formerTradeNames.split('*');
			return (
				<div>
					<div>
						{createName()}
						<div className="is-size-7">
							Former Trade Names:
							<br />
						</div>
					</div>
					{oldnames.map((formerName, i) => {
						return (
							<div className="is-size-7" key={i}>
								{formerName}
								<br />
							</div>
						);
					})}
				</div>
			);
		}}
	};

	const colorBox = (shade, handheld) => {
		if (shade !== '#FFFFFF') {
			return (
				<div
					className="colorBox is-size-7 has-text-centered"
					style={{ backgroundColor: shade }}
				>
					<br />
					{props.fluorescent && !handheld ? 'Fluorescent' : ''}
					{props.fluorescent && handheld ? 'F' : ''}
				</div>
			);
		}
		return (
			<div
				className="colorBox-bordered is-size-7 has-text-centered"
				style={{ backgroundColor: shade }}
			>
				<br />
				{props.fluorescent && !handheld ? 'Fluorescent' : ''}
				{props.fluorescent && handheld ? 'F' : ''}
			</div>
		);
	};

	const physicalFormStyle = () => {
		return (
			<div className={`column is-2 px-1 ${isLiquid ? 'liquidTableRow' : ''}`}>
				{titleCase(props.physicalType.toLowerCase())}
			</div>
		);
	};

	const getSDSLink = () => {
		// !!Need to verify spanish is primary language and there is a spanish pdf before doing this!
		if (browserLanguageSpanish && props.has_sds_es) {
			return (
				process.env.PUBLIC_URL + props.pdf_paths.sds + props.sds_filename_es
			);
		} else
			return process.env.PUBLIC_URL + props.pdf_paths.sds + props.sds_filename;
	};

	const tooltipPricesTablet = () => {
		return (
			<>
				{!props.noLiquid ? (
					<>
						<div className="columns">
							<div className="column mt-3 has-text-weight-bold is-underlined">
								{props.name}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								{isLiquid ? '2-lb Bottle' : '1-lb Jar (pwd/gran)'}
							</div>
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								${Number(props.price_1lb_Jar).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								{isLiquid ? '9-lb Jug' : '5-lb Jar (pwd/gran)'}
							</div>
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								${Number(props.price_5lb_Jar).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								{isLiquid ? '45-lb Pail' : '25-lb Pail (pwd/gran)'}
							</div>
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								${Number(props.price_25gal_Pail).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								{isLiquid ? '270-lb Drum' : '100-lb Drum (pwd/gran)'}
							</div>
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								${Number(props.price_small_drum).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								{isLiquid ? '450-lb Drum' : '250-lb Drum (pwd/gran)'}
							</div>
							<div
								className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
							>
								${Number(props.price_large_drum).toFixed(2)}
							</div>
						</div>
						{isLiquid ? (
							<div className="columns is-mobile is-size-7">
								<div
									className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
								>
									2250-lb Tote
								</div>
								<div
									className={`column is-6 ${isLiquid ? 'liquidTableRow' : ''}`}
								>
									{props.price_tote !== 0
										? '$' + Number(props.price_tote).toFixed(2)
										: 'NA'}
								</div>
							</div>
						) : (
							''
						)}
					</>
				) : !props.floralAbsorption ? (
					// Immersion (3 price col only)
					<>
						<div className="columns">
							<div className="column mt-3 has-text-weight-bold is-underlined">
								{props.name}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div className="column is-6">1-lb Jar (pwd/gran)</div>
							<div className="column is-6">
								${Number(props.price_1lb_Jar).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div className="column is-6">5-lb Jar (pwd/gran)</div>
							<div className="column is-6">
								${Number(props.price_5lb_Jar).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div className="column is-6">25-lb Pail (pwd/gran)</div>
							<div className="column is-6">
								{' '}
								${Number(props.price_25gal_Pail).toFixed(2)}
							</div>
						</div>
					</>
				) : (
					// Floral Absorption1
					<>
						<div className="columns">
							<div className="column mt-3 has-text-weight-bold is-underlined">
								{props.name}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div className="column is-6">1-lb Jar (pwd/gran)</div>
							<div className="column is-6">
								${Number(props.price_1lb_Jar).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div className="column is-6">5-lb Jar (pwd/gran)</div>
							<div className="column is-6">
								${Number(props.price_5lb_Jar).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div className="column is-6">25-lb Pail (pwd/gran)</div>
							<div className="column is-6">
								{' '}
								${Number(props.price_25gal_Pail).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div className="column is-6">100-lb Drum (pwd/gran)</div>
							<div className="column is-6">
								${Number(props.price_small_drum).toFixed(2)}
							</div>
						</div>
						<div className="columns is-mobile is-size-7">
							<div className="column is-6">250-lb Drum (pwd/gran)</div>
							<div className="column is-6">
								${Number(props.price_large_drum).toFixed(2)}
							</div>
						</div>
					</>
				)}
				<div className="columns is-mobile">
					{!props.noTDS ? (
						<div className="column is-4 is-offset-2">
							{props.has_link ? (
								<a
									href={
										process.env.PUBLIC_URL +
										props.pdf_paths.tds +
										props.link_url
									}
									target="_blank"
									rel="noreferrer"
								>
									<img src={tds_icon} alt="TDS Icon" />
								</a>
							) : (
								<div className="column is-6"></div>
							)}
						</div>
					) : null}
					{!props.noSDS ? (
						<div className="column is-4">
							{props.has_sds ? (
								<a href={getSDSLink()} target="_blank" rel="noreferrer">
									<img src={sds_icon} alt="SDS Icon" />
								</a>
							) : (
								<div className="column is-6"></div>
							)}
						</div>
					) : null}
				</div>
			</>
		);
	};

	return (
		<>
			<RES.Desktop>
				<div
					id={props.id}
					className="columns is-centered"
					style={{ marginTop: '20px' }}
					key={props.id}
				>
					<div className="column is-8">
						<div className="columns is-variable is-0-mobile is-1-tablet is-1-desktop">
							<div className="column is-3">
								<div className="columns">
									<div className="column is-6">
										{colorBox(props.lightShade)}
									</div>
									<div className="column is-6">{colorBox(props.darkShade)}</div>
								</div>
							</div>
							<div className="column is-2 has-text-centered is-size-7-mobile">
								{props.partNo}
							</div>
							{props.displayPhysicalForm ? (
								<React.Fragment>
									<div className="column is-3 is-size-7-mobile">
										{formerNames()}
									</div>
									{physicalFormStyle()}
								</React.Fragment>
							) : (
								<div className="column is-5 is-size-7-mobile">
									{formerNames()}
								</div>
							)}
							{!props.noTDS ? (
								<div className="column is-1">
									{props.has_link ? (
										<a
											href={
												process.env.PUBLIC_URL +
												props.pdf_paths.tds +
												props.link_url
											}
											target="_blank"
											rel="noreferrer"
										>
											<img src={tds_icon} alt="TDS Icon" />
										</a>
									) : (
										<div>N/A</div>
									)}
								</div>
							) : null}
							{!props.noSDS ? (
								<div className="column is-1">
									{props.has_sds ? (
										<a href={getSDSLink()} target="_blank" rel="noreferrer">
											<img src={sds_icon} alt="SDS Icon" />
										</a>
									) : (
										<div>N/A</div>
									)}
								</div>
							) : null}
						</div>
					</div>
					<div className="column is-4">
						
						{!props.noLiquid ? (
							<div className="columns">
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_1lb_Jar).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_5lb_Jar).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_25gal_Pail).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_small_drum).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_large_drum).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									{props.price_tote !== 0
										? '$' + Number(props.price_tote).toFixed(2)
										: 'NA'}
								</div>
							</div>
						) : !props.floralAbsorption ? (
							// Immersion - has only 3 price cols
							<div className="columns">
								<div className="column is-4 is-size-7-mobile">
									${Number(props.price_1lb_Jar).toFixed(2)}
								</div>
								<div className="column is-4 is-size-7-mobile">
									${Number(props.price_5lb_Jar).toFixed(2)}
								</div>
								<div className="column is-4 is-size-7-mobile">
									${Number(props.price_25gal_Pail).toFixed(2)}
								</div>
							</div>
						) : (
							<div className="columns">
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_1lb_Jar).toFixed(2)}
								</div>
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_5lb_Jar).toFixed(2)}
								</div>
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_25gal_Pail).toFixed(2)}
								</div>
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_small_drum).toFixed(2)}
								</div>
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_large_drum).toFixed(2)}
								</div>
							</div>
						)}
					</div>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div
					id={props.id}
					className="columns is-centered is-size-7 px-1 is-mobile"
					style={{ marginTop: '20px' }}
					key={props.id}
				>
					<div className="column">
						<div className="columns is-mobile">
							<div className="column is-3">
								<div className="columns is-mobile">
									<div className="column is-6">
										{colorBox(props.lightShade, true)}
									</div>
									<div className="column is-6">
										{colorBox(props.darkShade, true)}
									</div>
								</div>
							</div>
							<div className="column is-2 has-text-centered is-size-7-mobile">
								{props.partNo}
							</div>
							{props.displayPhysicalForm ? (
								<React.Fragment>
									<div className="column is-3 px-1">{formerNames()}</div>
									{physicalFormStyle()}
								</React.Fragment>
							) : (
								<div className="column is-5">{formerNames()}</div>
							)}
							<div className="column is-1 priceTooltipTablet">
								<img className="mobile-info-icon" src={infoIcon} alt="info" />
								<span className="priceTooltipTablettext">
									{tooltipPricesTablet()}
								</span>
							</div>
						</div>
					</div>
					{/* <div className="column is-4">
						{!props.noLiquid ? (
							<div className="columns">
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_1lb_Jar).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_5lb_Jar).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_25gal_Pail).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_small_drum).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									${Number(props.price_large_drum).toFixed(2)}
								</div>
								<div
									className={`column is-2 is-size-7-mobile ${
										isLiquid ? 'liquidTableRow' : ''
									}`}
								>
									{props.price_tote !== 0
										? '$' + Number(props.price_tote).toFixed(2)
										: 'NA'}
								</div>
							</div>
						) : !props.floralAbsorption ? (
							<div className="columns">
								<div className="column is-4 is-size-7-mobile">
									${Number(props.price_1lb_Jar).toFixed(2)}
								</div>
								<div className="column is-4 is-size-7-mobile">
									${Number(props.price_5lb_Jar).toFixed(2)}
								</div>
								<div className="column is-4 is-size-7-mobile">
									${Number(props.price_25gal_Pail).toFixed(2)}
								</div>
							</div>
						) : (
							<div className="columns">
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_1lb_Jar).toFixed(2)}
								</div>
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_5lb_Jar).toFixed(2)}
								</div>
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_25gal_Pail).toFixed(2)}
								</div>
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_small_drum).toFixed(2)}
								</div>
								<div className={`column is-2 is-size-7-mobile`}>
									${Number(props.price_large_drum).toFixed(2)}
								</div>
							</div>
						)}
					</div> */}
				</div>
			</RES.Handheld>
		</>
	);
}
