import React from 'react';

export default function SurfactantsHeaderRow(props) {
	// const rowHeight = { height: '70px' };
	return (
		<div>
			<div className="title has-text-centered has-background-white mb-0">
				{props.title}
			</div>
			<div className="columns has-background-white">
				<div className="column is-2 has-text-weight-bold">Part No.</div>
				<div className="column is-2 has-text-weight-bold">Product Name</div>

				<div className="column  is-2 has-text-weight-bold">
					Quart Bottle
					<br />
					Price/bottle
				</div>

				<div className="column is-2 has-text-weight-bold">
					Gallon Jug
					<br />
					Price/Gal
				</div>
				<div className="column is-2 has-text-weight-bold">
					5-gal Pail
					<br />
					Price/Gal
				</div>
				<div className="column is-2 has-text-weight-bold">
					55-Gal Drum
					<br />
					Price/Gal
				</div>
			</div>
		</div>
	);
}
