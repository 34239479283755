import React, { useEffect, useState } from 'react';

export default function DyesFilters(props) {
	// State of filter check boxes
	const [filterColors, setFilterColors] = useState(props.filterColors);
	const [filterPhysicalForms, setFilterPhysicalForms] = useState(
		props.filterPhysicalForms
	);

	// useEffect(() => {
	// 	// console.log(props.filterColors)
	// 	const colors = props.filterColors;
	// 	setFilterColors(colors);
	// 	const physicalForms = props.filterPhysicalForms;
	// 	setFilterPhysicalForms(physicalForms);
	// }, []);

	// triggers the filtercollection and parent callback on
	useEffect(() => {
		const filterKeys = { colors: [], physicalForms: [] };
		for (let colorKey in filterColors) {
			if (filterColors[colorKey]) filterKeys['colors'].push(colorKey);
		}

		for (let colorKey in filterPhysicalForms) {
			if (filterPhysicalForms[colorKey])
				filterKeys['physicalForms'].push(colorKey);
		}
		props.onClick(filterKeys);
		// console.log(filterKeys)
	}, [filterColors, filterPhysicalForms, props]);

	const onChangeColor = (e) => {
		const toggle = !filterColors[e.target.name];
		// console.log(toggle, e.target.name);
		setFilterColors({ ...filterColors, [e.target.name]: toggle });
	};

	const onChangePhysicalForms = (e) => {
		const toggle = !filterPhysicalForms[e.target.name];
		// console.log(toggle, e.target.name);
		setFilterPhysicalForms({ ...filterPhysicalForms, [e.target.name]: toggle });
	};

	const generateColorFilterDisplay = () => {
		let colorList = [];
		for (var key in filterColors) {
			colorList.push(
				<div className="column is-1" key={key}>
					<label className="checkbox is-capitalized has-text-white">
						<input
							type="checkbox"
							name={key}
							onClick={onChangeColor}
							value={filterColors[key]}
						/>
						{' ' + key}
					</label>
				</div>
			);
		}
		return colorList;
	};

	const generatePhysiclFormFilterDisplay = () => {
		let physicalFormList = [];
		for (var key in filterPhysicalForms) {
			physicalFormList.push(
				<div className="column is-4" key={key}>
					<label className="checkbox is-capitalized has-text-white">
						<input
							type="checkbox"
							name={key}
							onClick={onChangePhysicalForms}
							value={filterPhysicalForms[key]}
						/>
						{' ' + key}
					</label>
				</div>
			);
		}

		return physicalFormList;
	};

	return props.displayPhysicalForm ? (
		<div className="mb-3">
			<div className="columns has-background-link color-filter">
				<div className="column has-text-centered has-text-white has-text-weight-bold is-3">
					Physical Form Filter
				</div>
				<div className="column has-text-centered has-text-white has-text-weight-bold is-9">
					Color Filter
				</div>
			</div>
			<div className="columns is-centered has-background-link color-filter">
				<div className="column has-text-centered is-3">
					<div className="columns is-centered">
						{generatePhysiclFormFilterDisplay()}
					</div>
				</div>
				<div className="column is-9">
					<div className="columns is-centered">
						{generateColorFilterDisplay()}
					</div>
				</div>
			</div>
			<div className="columns is-centered has-background-link color-filter">
				<div className="column has-text-centered has-text-white has-text-weight-bold">
					[Total displayed: {props.total}]
				</div>
			</div>
		</div>
	) : (
		<div className="mb-3">
			<div className="columns has-background-link color-filter">
				<div className="column has-text-centered has-text-white has-text-weight-bold">
					Color Filter
				</div>
			</div>
			<div className="columns is-centered has-background-link color-filter">
				<div className="column">
					<div className="columns is-centered">
						{generateColorFilterDisplay()}
					</div>
				</div>
			</div>
			<div className="columns is-centered has-background-link color-filter">
				<div className="column has-text-centered has-text-white has-text-weight-bold">
					[Total displayed: {props.total}]
				</div>
			</div>
		</div>
	);
}
