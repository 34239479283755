import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSortUp,
	faSortDown,
	faSort,
} from '@fortawesome/free-solid-svg-icons';

export default function DyeConversionHeaderRow(props) {
	// const rowHeight = { height: '70px' };

	const sortIcon = (col) => {
		if (col === props.sortData.column) {
			if (props.sortData.sortOrder === 'up') {
				return faSortUp;
			} else if (props.sortData.sortOrder === 'down') {
				return faSortDown;
			}
		}
		return faSort;
	};

	const sortClick = (e) => {
		props.sortClick(e);
	};

	return (
		<div>
			<div className="title has-text-centered has-background-white mb-0">
				{props.title}
			</div>
			<div className="columns has-background-white">
				<div className="column has-text-centered">
					<br />
				</div>
			</div>
			<div className="columns mb-2 has-background-primary has-text-white">
				<div className="column is-1 has-text-weight-bold"></div>
				<div className="column is-2">
					<button
						className="button has-text-weight-bold is-info"
						onClick={sortClick}
						name="previousRKIPartNo"
					>
						Old Product Code
						<FontAwesomeIcon
							style={{ pointerEvents: 'none' }}
							icon={sortIcon('previousRKIPartNo')}
						/>
					</button>
				</div>
				<div className="column is-3 has-text-weight-bold">
					<button
						className="button has-text-weight-bold is-info"
						onClick={sortClick}
						name="previousProductName"
					>
						Old Product Name
						<FontAwesomeIcon
							style={{ pointerEvents: 'none' }}
							icon={sortIcon('previousProductName')}
						/>
					</button>
				</div>
				<div className="column is-2  has-text-weight-bold">
					<button
						className="button has-text-weight-bold is-info"
						onClick={sortClick}
						name="newRKIPartNo"
					>
						New Product Code
						<FontAwesomeIcon
							style={{ pointerEvents: 'none' }}
							icon={sortIcon('newRKIPartNo')}
						/>
					</button>
				</div>
				<div className="column is-3  has-text-weight-bold">
					<button
						className="button has-text-weight-bold is-info"
						onClick={sortClick}
						name="newRKIProductName"
					>
						New Product Name
						<FontAwesomeIcon
							style={{ pointerEvents: 'none' }}
							icon={sortIcon('newRKIProductName')}
						/>
					</button>
				</div>
			</div>
		</div>
	);
}
