import React from 'react';

export default function Error404() {
	return (
		<div className="section">
			<div className="notification is-info">
				<div className="title has-text-centered">Page not found</div>
			</div>
		</div>
	);
}
