import React from 'react';
import * as RES from '../../../helpers/responsive';

export default function SolventHeaderRow(props) {
	return (
		<>
			<RES.Desktop>
				<div>
					<div className="columns title has-text-centered has-background-white">
						<div className="column">{props.title}</div>
					</div>
					<div className="columns has-background-white is-mobile">
						<div className="column is-8">
							<div className="columns is-mobile">
								<div className="column has-text-centered">
									<br />
								</div>
							</div>
							<div className="columns is-variable is-0-mobile is-1-tablet is-1-desktop is-mobile">
								<div className="column is-3">
									<div className="columns">
										<div className="column is-6 has-text-weight-bold is-size-7-mobile">
											Light Shade
										</div>
										<div className="column is-6 has-text-weight-bold is-size-7-mobile">
											Heavy Shade
										</div>
									</div>
								</div>

								<div className="column is-2 has-text-centered has-text-weight-bold is-size-7-mobile">
									Product Code
								</div>
								<React.Fragment>
									<div className="column is-3 has-text-weight-bold is-size-7-mobile">
										Product Name
									</div>
									<div className="column is-2 has-text-weight-bold is-size-7-mobile">
										Physical Form
									</div>
								</React.Fragment>

								{!props.noTDS ? (
									<div className="column is-1 has-text-weight-bold is-size-7-mobile">
										TDS
									</div>
								) : null}
								{!props.noSDS ? (
									<div className="column is-1 has-text-weight-bold is-size-7-mobile">
										SDS
									</div>
								) : null}
							</div>
						</div>

						<div className="column is-4">
							<div className="columns is-mobile">
								<div className="column has-text-centered has-text-weight-bold is-size-7-mobile">
									Price per pound
								</div>
							</div>
							{!props.noLiquid ? (
								<div className="columns is-mobile">
									{/* <div className="column is-4 has-text-weight-bold is-size- is-size-7-mobile">
										<span className="">
											{' '}
											2-lb {!props.nps ? 'Bottle' : 'Can'} (liq)
										</span>
									</div> */}
									<div className="column is-6 has-text-weight-bold is-size- is-size-7-mobile">
										<span className="">
											{!props.nps ? '9-lb Jug' : '8-lb Can'} (liq)
										</span>
									</div>
									<div className="column is-6 has-text-weight-bold is-size-">
										<span className="">
											{' '}
											{!props.nps ? '45' : '40'}-lb Pail (liq)
										</span>
									</div>
								</div>
							) : (
								<div className="columns is-mobile">
									<div className="column is-4 has-text-weight-bold is-size-7 is-size-7-mobile">
										1-lb Jar {!props.noPwdGran ? '(pwd/gran)' : null}
									</div>
									<div className="column is-4 has-text-weight-bold is-size-7 is-size-7-mobile">
										5-lb Jar
									</div>
									<div className="column is-4 has-text-weight-bold is-size-7">
										25-lb Pail {!props.noPwdGran ? '(pwd/gran)' : null}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="columns header-bottom-border has-background-link">
						<div className="column"></div>
					</div>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div className="has-background-primary has-text-white is-size-7 px-1">
					<div className="columns is-mobile">
						<div className="column  is-size-5">{props.title}</div>
					</div>
					<div className="columns is-mobile">
						<div className="column is-3">
							<div className="columns is-mobile">
								<div className="column is-6 has-text-weight-bold">
									Light Shade
								</div>
								<div className="column is-6 has-text-weight-bold">
									Heavy Shade
								</div>
							</div>
						</div>
						<div className="column is-2 has-text-centered has-text-weight-bold is-size-7-mobile">
							Product Code
						</div>
						<React.Fragment>
							<div className="column is-3 has-text-weight-bold is-size-7-mobile">
								Product Name
							</div>
							<div className="column is-2 has-text-weight-bold is-size-7-mobile">
								Physical Form
							</div>
						</React.Fragment>

						<div className="column is-1 has-text-weight-bold">Info</div>
						{/* {!props.noTDS ? (
									<div className="column is-1 has-text-weight-bold is-size-7-mobile">
										TDS
									</div>
								) : null}
								{!props.noSDS ? (
									<div className="column is-1 has-text-weight-bold is-size-7-mobile">
										SDS
									</div>
								) : null} */}

						{/* <div className="column is-4">
							<div className="columns is-mobile">
								<div className="column has-text-centered has-text-weight-bold is-size-7-mobile">
									Price per pound
								</div>
							</div>
							{!props.noLiquid ? (
								<div className="columns is-mobile">
									<div className="column is-4 has-text-weight-bold is-size- is-size-7-mobile">
										<span className="">
											{' '}
											2-lb {!props.nps ? 'Bottle' : 'Can'} (liq)
										</span>
									</div>
									<div className="column is-4 has-text-weight-bold is-size- is-size-7-mobile">
										<span className="">
											{!props.nps ? '9-lb Jug' : '8-lb Can'} (liq)
										</span>
									</div>
									<div className="column is-4 has-text-weight-bold is-size-">
										<span className="">
											{' '}
											{!props.nps ? '45' : '40'}-lb Pail (liq)
										</span>
									</div>
								</div>
							) : (
								<div className="columns is-mobile">
									<div className="column is-4 has-text-weight-bold is-size-7 is-size-7-mobile">
										1-lb Jar {!props.noPwdGran ? '(pwd/gran)' : null}
									</div>
									<div className="column is-4 has-text-weight-bold is-size-7 is-size-7-mobile">
										5-lb Jar
									</div>
									<div className="column is-4 has-text-weight-bold is-size-7">
										25-lb Pail {!props.noPwdGran ? '(pwd/gran)' : null}
									</div>
								</div>
							)}
						</div> */}
					</div>
					<div className="columns header-bottom-border has-background-link">
						<div className="column"></div>
					</div>
				</div>
			</RES.Handheld>
		</>
	);
}
