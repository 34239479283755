import React, { createContext, useState, useEffect } from 'react';
import browserLang from 'browser-lang';

export const LanguageContext = createContext();

const LanguageContextProvider = (props) => {
	const [browserLanguageSpanish, setBrowserLanguageSpanish] = useState(false);

	// True is Spanish is a preferred language
	useEffect(() => {
		const myLanguage = browserLang({
			languages: ['es-mx'],
			fallback: 'en',
		});
		setBrowserLanguageSpanish(myLanguage.includes('es'));
	}, []);


	return (
		<LanguageContext.Provider value={{ browserLanguageSpanish }}>
			{props.children}
		</LanguageContext.Provider>
	);
};

export default LanguageContextProvider;
