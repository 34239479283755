import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import TableHeader from '../../components/dbDisplay/aux-products/preservatives/preservativesHeaderRow';
import TableRow from '../../components/dbDisplay/aux-products/preservatives/preservativesRow';

import { useHistory } from 'react-router-dom';

export default function Preservatives(props) {
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const [preservatives, setPreservatives] = useState({});

	useEffect(() => {
		const ref = firebase.database().ref('aux-products/preservatives');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					newState.push({
						id: item,
						RKIPartNo: items[item].RKIPartNo,
						name: items[item].name,
						price_1lb_jar: items[item].price_1lb_jar,
						price_5lb_jar: items[item].price_5lb_jar,
						price_25_gal_pail: items[item].price_25_gal_pail,
						price_55_gal_box: items[item].price_55_gal_box,
					});
				}

				setPreservatives(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<div className="container">
			<Helmet>
				<title>Koch Color - Preservatives</title>
				<meta
					name="description"
					content="Aqueous dye solutions need to be protected from spoilage due to microbial contamination resulting from the growth of bacteria, yeast and mold. When preparing concentrated stock solutions of water-soluble dyes, adding a preservative will significantly extend shelf life. We offer Potassium Sorbate (PS), Sodium Benzoate (SB) and Citric Acid (CA). All of these items are approved for food and cosmetic use by the US Food & Drug Administration. They are considered generally recognized as safe (GRAS) and are exempt from EPA registration. Sodium Benzoate is primarily used to control bacteria. Potassium Sorbate is mainly effective against yeast and mold. These two preservatives are often used together for a broad spectrum of protection. PS and SB function best in an acidic environment. Citric acid can be used to reduce the pH to the optimal range where they function best, typically 3.0 – 5.0. The recommended use rate is 0.1% - 0.2% on weight of solution."
				/>
			</Helmet>
			<div className="section box ob-section">
				<div className="title has-text-centered ">Preservatives</div>
				<div className="block subtitle has-text-centered ">
					Potassium Sorbate, Sodium Benzoate, Citric Acid
				</div>
				<div className="block is-size-4">Description, Applications & Use</div>
				<div className="block">
					<p className="block">
						Aqueous dye solutions need to be protected from spoilage due to
						microbial contamination resulting from the growth of bacteria, yeast
						and mold. When preparing concentrated stock solutions of
						water-soluble dyes, adding a preservative will significantly extend
						shelf life. We offer Potassium Sorbate (PS), Sodium Benzoate (SB)
						and Citric Acid (CA). All of these items are approved for food and
						cosmetic use by the US Food & Drug Administration. They are
						considered generally recognized as safe (GRAS) and are exempt from
						EPA registration. Sodium Benzoate is primarily used to control
						bacteria. Potassium Sorbate is mainly effective against yeast and
						mold. These two preservatives are often used together for a broad
						spectrum of protection. PS and SB function best in an acidic
						environment. Citric acid can be used to reduce the pH to the optimal
						range where they function best, typically 3.0 – 5.0. The recommended
						use rate is 0.1% - 0.2% on weight of solution.
					</p>
				</div>
			</div>

			<div className="columns">
				<div className="column">
					<div className="box">
						<section className="">
							<TableHeader />
							{preservatives && preservatives.length > 0 ? (
								preservatives.map((item) => {
									if (!searchId) {
										return <TableRow {...item} key={item.id} />;
									} else {
										if (item.id === searchId) {
											return (
												<>
													<TableRow {...item} key={item.id} />
													<button
														className="button is-info is-small"
														onClick={onSearchClearClicked}
													>
														Clear Search Results
													</button>
												</>
											);
										}
										return null;
									}
								})
							) : (
								<p>This table does is empty or does not exist</p>
							)}
						</section>
					</div>
				</div>
			</div>
		</div>
	);
}
