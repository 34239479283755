import React from 'react';
import { Helmet } from 'react-helmet-async';
import Carousel from './Carousel';

export default function Home() {
	return (
		<div>
			<Helmet>
				<title>Koch Color - Home</title>
			</Helmet>
			<Carousel></Carousel>
			<div className="section">
				<div className="container"></div>
				<div className="title has-text-centered">Welcome to Koch Color</div>
			</div>
		</div>
	);
}
