import {useState} from 'react';
import { useFormik } from 'formik';
import emailjs from 'emailjs-com';
import { NavLink } from 'react-router-dom';


// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values) => {
	const errors = {};
	if (!values.firstName) {
		errors.firstName = 'Required';
	} else if (values.firstName.length > 15) {
		errors.firstName = 'Must be 15 characters or less';
	}

	if (!values.lastName) {
		errors.lastName = 'Required';
	} else if (values.lastName.length > 20) {
		errors.lastName = 'Must be 20 characters or less';
	}

	if (!values.email) {
		errors.email = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if(!values.phone) {
		errors.phone = 'Required';
	} else if (!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i.test(values.phone)){
		errors.phone = 'Invalid phone number. Format: 123-456-7890';
	}

	if(!values.comments){
		errors.comments = 'Required';
	} 

	return errors;
};

export const ContactForm = () => {
	const [redirect, setRedirect] = useState(false)
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			company: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			country: '',
			zip: '',
			phone: '',
			comments: '',
		},
		validate,
		onSubmit: (values) => {
			// alert(JSON.stringify(values, null, 2));
			sendEmail(values)
		},
	});
	
	const sendEmail = (values) =>{
		emailjs
		.send('service_ed623ph', 'template_0117med', values,"user_46gaiJ86keAYnbz1AWxQU")
		.then(
			(result) => {
				setRedirect(true)
				},
				(error) => {
					console.log(error.text);
				}
			);
	}

	return (
		<div>
			{!redirect ? (
				<div className="has-text-centered">
					<div className="is-size-3 has-text-white mb-4">Contact Us</div>
					<form onSubmit={formik.handleSubmit}>
						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">Name</label>
							</div>
							<div className="field-body">
								<div className="field">
									<p className="control is-expanded">
										<input
											className="input"
											id="firstName"
											name="firstName"
											type="text"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.firstName}
											placeholder="First"
										/>
									</p>
									{formik.touched.firstName && formik.errors.firstName ? (
										<div className="has-text-danger">
											{formik.errors.firstName}
										</div>
									) : null}
								</div>
								<div className="field">
									<p className="control is-expanded">
										<input
											className="input"
											id="lastName"
											name="lastName"
											type="text"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.lastName}
											placeholder="Last"
										/>
									</p>
									{formik.touched.lastName && formik.errors.lastName ? (
										<div className="has-text-danger">
											{formik.errors.lastName}
										</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">Email</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="email"
											name="email"
											type="email"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.email}
											placeholder="Email Address"
										/>
										{formik.touched.email && formik.errors.email ? (
											<div className="has-text-danger">
												{formik.errors.email}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">Company</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="company"
											name="company"
											type="company"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.company}
											placeholder="Company"
										/>
										{formik.touched.company && formik.errors.company ? (
											<div className="has-text-danger">
												{formik.errors.company}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="address1">
									Address 1
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="address1"
											name="address1"
											type="address1"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.address1}
										/>
									</div>
									{formik.touched.address1 && formik.errors.address1 ? (
										<div>{formik.errors.address1}</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="address2">
									Address 2
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="address2"
											name="address2"
											type="address2"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.address2}
										/>
									</div>
									{formik.touched.address2 && formik.errors.address2 ? (
										<div>{formik.errors.address2}</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="city">
									City
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="city"
											name="city"
											type="city"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.city}
										/>
									</div>
									{formik.touched.city && formik.errors.city ? (
										<div>{formik.errors.city}</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="state">
									State/Province
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="state"
											name="state"
											type="state"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.state}
										/>
									</div>
									{formik.touched.state && formik.errors.state ? (
										<div>{formik.errors.state}</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="country">
									Country
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="country"
											name="country"
											type="country"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.country}
										/>
									</div>
									{formik.touched.country && formik.errors.country ? (
										<div>{formik.errors.country}</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="zip">
									Zip/Postal Code
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="zip"
											name="zip"
											type="zip"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.zip}
										/>
									</div>
									{formik.touched.zip && formik.errors.zip ? (
										<div>{formik.errors.zip}</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="phone">
									Phone Number
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="phone"
											name="phone"
											type="phone"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.phone}
											placeholder="123-456-7890"
										/>
									</div>
									{formik.touched.phone && formik.errors.phone ? (
										<div className="has-text-danger">{formik.errors.phone}</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="comments">
									Comments
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<textarea
											className="textarea"
											id="comments"
											name="comments"
											type="comments"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.comments}
										/>
									</div>
									{formik.touched.comments && formik.errors.comments ? (
										<div className="has-text-danger">
											{formik.errors.comments}
										</div>
									) : null}
								</div>
							</div>
						</div>

						<button className="button is-success my-4" type="submit">
							Submit
						</button>
					</form>
				</div>
			) : (
				<div className="has-text-centered py-4">
					<p className="title has-text-white mt-4">
						Your Request Was Successfully Submitted
					</p>
					<p className="subtitle has-text-white">
						Thank you for your business!
					</p>

					<NavLink to="/" className="button mb-4">
						OK
					</NavLink>
				</div>
			)}
		</div>
	);
};
