import firebase from 'firebase/app';
import 'firebase/database'

firebase.initializeApp({
	apiKey: 'AIzaSyBOST2TuW6sxcidWqXO_8sMxOCua8ZgoTA',
	authDomain: 'colortest-84bac.firebaseapp.com',
	databaseURL: 'https://colortest-84bac.firebaseio.com',
	projectId: 'colortest-84bac',
	storageBucket: 'colortest-84bac.appspot.com',
	messagingSenderId: '1097834714023',
	appId: '1:1097834714023:web:db77e089e078c17f861c82',
})

export default firebase;
// const db = firebase.database(config);
// export default db
// Initialize Firebase
// firebase.initializeApp(config);
// export const providerGoogle = new firebase.auth.GoogleAuthProvider();
// export const providerEmail = new firebase.auth.EmailAuthProvider();
// export const auth = firebase.auth();


