import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import ConversionButton from '../../components/dbDisplay/dyes/conversionButton';

import GeneralTable from '../../components/dbDisplay/dyes/generalTable';

import moreliquid from '../../images/dyes/alkaline/moreliquid.jpg';
import high_ph_scale_img from '../../images/dyes/alkaline/alk_hiph-scale.png';
import high_ph_symbol_img from '../../images/dyes/alkaline/high_pH_symbol.png';
import { useHistory } from 'react-router-dom';
import * as RES from '../../helpers/responsive';

export default function Alkaline(props) {
	const [dyes, setDyes] = useState({});
	const [filteredDyes, setFilteredDyes] = useState({});
	const [searchId, setSearchId] = useState(null);
	const history = useHistory();
	const pdf_paths = {
		tds: '/PDF Collection/2000-TDS/',
		sds: '/PDF Collection/SDS Files/2000-SDS/',
	};

	const filters = {
		physicalForms: {
			powder: false,
			liquid: false,
			granular: false,
		},
		colors: {
			red: false,
			green: false,
			blue: false,
			yellow: false,
			orange: false,
			violet: false,
		},
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/general');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					if (items[item].high_ph) {
						newState.push({
							id: item,
							sort_order: items[item].sort_order,
							partNo: items[item].RKIPartNo,
							name: items[item].name,
							descriptor: items[item].descriptor ? items[item].descriptor : '',
							lightShade: items[item].lightShade,
							darkShade: items[item].darkShade,
							physicalType: items[item].physicalType,
							price_1lb_Jar: items[item].price_1lb_Jar,
							price_5lb_Jar: items[item].price_5lb_Jar,
							price_25gal_Pail: items[item].price_25gal_Pail,
							price_small_drum: items[item].price_small_drum,
							price_large_drum: items[item].price_large_drum,
							price_tote: items[item].price_tote ? items[item].price_tote : 0,
							primaryColor: items[item].primaryColor,
							primaryColorOrder: items[item].primColorOrder,
							formerTradeNames: items[item].formerTradeNames,
							fluorescent: items[item].fluorescent
								? items[item].fluorescent
								: false,
							has_link: items[item].has_link ? items[item].has_link : false,
							link_url: items[item].link_url ? items[item].link_url : '',
							has_sds: items[item].has_sds ? items[item].has_sds : false,
							sds_filename: items[item].sds_filename
								? items[item].sds_filename
								: false,
							inactive: items[item].inactive ? items[item].inactive : false,
						});
					}
				}
				newState.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
				setDyes(newState);
				setFilteredDyes(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	// filter color and physical forms. object of filter keys passed into function
	const onFilterClicked = (newFilterKeys) => {
		//filter by form first, then by colors.
		if (dyes.length) {
			// if no filters selected show all dyes
			if (
				newFilterKeys['colors'].length < 1 &&
				newFilterKeys['physicalForms'].length < 1
			) {
				setFilteredDyes(dyes);
				return;
			}

			//filter by form
			let filteredPhysicalFormDyes = [];
			if (newFilterKeys['physicalForms'].length < 1) {
				filteredPhysicalFormDyes = [...dyes];
			} else {
				filteredPhysicalFormDyes = dyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['physicalForms'].forEach((filterkey) => {
						if (dye.physicalType.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			}

			// WE NEED TO FILTER THE COLORS FROM THE RESULTS OF THE FORM FILTER, NOT FROM ALL DYES

			// //filter by color
			let filteredColorDyes = [];
			if (newFilterKeys['colors'].length > 0) {
				filteredColorDyes = filteredPhysicalFormDyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['colors'].forEach((filterkey) => {
						if (dye.primaryColor.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			} else {
				filteredColorDyes = filteredPhysicalFormDyes.concat(filteredColorDyes);
			}

			setFilteredDyes(filteredColorDyes);
		}
	};

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>
					Koch Color - Dyes for Alkaline (High pH) Products (pH{'>'}9)
				</title>
				<meta
					name="description"
					content="The dyes on this list have demonstrated improved stability in alkaline solutions. It’s very difficult to predict the upper stability limit as this depends on several factors and not just the pH value alone. In general, these colors remain stable up to pH 12. A few colors are stable up to 13. It is essential that thorough testing be completed on a sample basis before proceeding to use these dyes on a production level."
				/>
			</Helmet>
			<RES.Desktop>
				{' '}
				<div className="section pt-5">
					<div className="box">
						<div className="title has-text-centered ">
							Dyes for Alkaline (High pH) Products (pH{'>'}9)
						</div>
						<ConversionButton />
						<section className="container">
							<div className="columns is-vcentered">
								<div className="column">
									<img className="acid" src={high_ph_symbol_img} alt="" />
									<img className="acid" src={high_ph_scale_img} alt="" />
								</div>
								<div className="column is-1"></div>
								<div className="column is-6">
									<img className="" src={moreliquid} alt="" />
								</div>
							</div>
							<div className="subtitle  has-text-centered">
								For use in dyeing industrial products where color stability in
								alkaline (pH{'>'}9) environments is critical.
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								The dyes on this list have demonstrated improved stability in
								alkaline solutions. It’s very difficult to predict the upper
								stability limit as this depends on several factors and not just
								the pH value alone. In general, these colors remain stable up to
								pH 12. A few colors are stable up to 13. It is essential that
								thorough testing be completed on a sample basis before
								proceeding to use these dyes on a production level.
							</div>
							{/* <div className="block is-size-4">Use Rates</div>
					<div className="content">
						Typical use rates in water-based liquid solutions:
						<ul className="">
							<li>
								Very Light Shade
								<ul>
									<li>1-lb per 25,000 gallons (4.8 ppm)</li>
								</ul>
							</li>
							<li>
								Light Shade
								<ul>
									<li>1-lb per 10,000 gallons (12 ppm)</li>
								</ul>
							</li>
							<li>
								Medium Shade
								<ul>
									<li>1-lb per 5,000 gallons (24 ppm)</li>
								</ul>
							</li>
							<li>
								Dark Shade
								<ul>
									<li>1-lb per 2,000 gallons (60 ppm)</li>
								</ul>
							</li>
							<li>
								Very Dark Shade
								<ul>
									<li>1-lb per 500 gallons (240 ppm)</li>
								</ul>
							</li>
						</ul>
					</div>
					<div className="block">
						*Note: ppm means parts per million. When adding dye to liquid, 1ppm
						is equivalent to 1 milligram per liter (mg/l)
					</div>
					<div className="block is-size-4">Case Packaging</div>
					<div className="block">
						NOTE: Dyes are also available in case packaging. For powder dyes,
						cases contain 12 x 1-lb jars. For liquid dyes, cases contain 12 x
						1-quart bottles or 4 x 1 gallon jugs. A discount of $8.00/lb off of
						the 1-lb powder price, and $1.00 off of the quart and gallon prices
						shown above is given. All discounts apply only for cases of the same
						color.
					</div> */}
						</div>
					</div>
					<GeneralTable
						filteredDyes={filteredDyes}
						filterColors={filters.colors}
						filterPhysicalForms={filters.physicalForms}
						onFilterClicked={(value) => onFilterClicked(value)}
						title="Dyes for Alkaline (High pH) products (pH>9)"
						displayPhysicalForm={true}
						searchId={searchId}
						onSearchClear={onSearchClearClicked}
						pdf_paths={pdf_paths}
						noTDS={false}
					/>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div>
					<GeneralTable
						filteredDyes={filteredDyes}
						filterColors={filters.colors}
						filterPhysicalForms={filters.physicalForms}
						onFilterClicked={(value) => onFilterClicked(value)}
						title="Dyes for Alkaline (High pH) products (pH>9)"
						displayPhysicalForm={true}
						searchId={searchId}
						onSearchClear={onSearchClearClicked}
						pdf_paths={pdf_paths}
						noTDS={false}
					/>
				</div>
			</RES.Handheld>
		</>
	);
}
