import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import Sticky from 'react-stickynode';
import Filter from './Filters/DyesConversionFilter';
import TableHeader from '../../components/dbDisplay/dyes/dyesConversionHeaderRow';
import TableRow from '../../components/dbDisplay/dyes/dyeConversionRow';

export default function Conversion456() {
	const [sortedDyes, setSortedDyes] = useState({});
	const [sortData, setSortData] = useState({
		column: '',
		sortOrder: 'default',
	});
	const [filter, setFilter] = useState({});

	//Could there be a filter to show only 2000, series 4000 series, 5000, series, 6000 series etc
	const filterSelections = {
		2: false,
		4: false,
		5: false,
		6: false,
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/partNoConversions456');
		ref.on('value', (snapshot) => {
			let items = snapshot.val();

			let newState = [];
			for (let item in items) {
				newState.push({
					previousRKIPartNo: items[item].previousRKIPartNo,
					previousProductName: items[item].previousProductName,
					newRKIProductName: items[item].newRKIProductName,
					newRKIPartNo: items[item].newRKIPartNo,
					newPartNoShort: items[item].newPartNoShort,
					id: item,
				});
			}
			setSortedDyes(newState);
			setFilter(newState);
		});
	}, []);

	useEffect(() => {
		const currentDyes = filter;
		if (sortData.sortOrder === 'up') {
			currentDyes.sort((a, b) =>
				a[sortData.column] > b[sortData.column] ? 1 : -1
			);
			setFilter([...currentDyes]);
		} else if (sortData.sortOrder === 'down') {
			currentDyes.sort((a, b) =>
				a[sortData.column] < b[sortData.column] ? 1 : -1
			);
			setFilter([...currentDyes]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortData]);

	const onChangeSort = (e) => {
		if (e.target.name === sortData.column) {
			if (sortData.sortOrder === 'up')
				setSortData({ column: e.target.name, sortOrder: 'down' });
			else if (sortData.sortOrder === 'down')
				setSortData({ column: e.target.name, sortOrder: 'up' });
		} else {
			setSortData({ column: e.target.name, sortOrder: 'up' });
		}
	};

	const onFilterClicked = (newFilter) => {
		// console.log(newFilter);
		if (sortedDyes.length) {
			if (newFilter.length < 1) {
				setFilter(sortedDyes);
				return;
			}
			const filteredDyes = sortedDyes.filter((sortedDyes) => {
				// return true if match
				let found = false;
				newFilter.forEach((filterkey) => {
					if (
						sortedDyes.previousRKIPartNo
							.toString()
							.charAt(0)
							.includes(filterkey)
					) {
						found = true;
					}
					return true;
				});
				return found;
			});
			setFilter(filteredDyes);
		}
	};

	return (
		<div className="section pt-5">
			<Helmet>
				<title>Koch Color - Product Code Conversion</title>
				<meta
					name="description"
					content="Water soluble dyes with product codes belonging to the 4000, 5000 and
					6000 series have been consolidated into the 2000 series. This
					conversion table has been created to cross reference new and old
					codes. In the past some dyes were sold under more than one product
					code and trade name for different applications. To reduce confusion
					and promote simplicity, product codes for our water soluble dyes have
					been consolidated into the 2000 series. Updated web menu options show
					which dyes are best suited for specific applications."
				/>
			</Helmet>
			<div className="box">
				<div className="title has-text-centered ">Product Code Conversion</div>
				<div>
					Water soluble dyes with product codes belonging to the 4000, 5000 and
					6000 series have been consolidated into the 2000 series. This
					conversion table has been created to cross reference new and old
					codes. In the past some dyes were sold under more than one product
					code and trade name for different applications. To reduce confusion
					and promote simplicity, product codes for our water soluble dyes have
					been consolidated into the 2000 series. Updated web menu options show
					which dyes are best suited for specific applications.
				</div>
			</div>

			<div className="columns">
				<div className="column is-2">
					<Sticky enabled={true} top={104}>
						<Filter
							className=""
							onClick={onFilterClicked}
							total={filter.length}
							filterCodes={filterSelections}
						/>
					</Sticky>
				</div>
				<div className="column">
					<div className="box">
						<section className="">
							<Sticky enabled={true} top={64}>
								<TableHeader
									title=""
									sortData={sortData}
									sortClick={onChangeSort}
								/>
							</Sticky>
							{filter && filter.length > 0 ? (
								filter.map((item, index) => {
									// return null
									return (
										<TableRow
											{...item}
											key={item.id}
											backgroundColor={index % 2}
										/>
									);
								})
							) : (
								<progress className="progress is-small is-primary" max="100">
									15%
								</progress>
							)}
						</section>
					</div>
				</div>
			</div>
		</div>
	);
}
