import React from 'react';
// import './tablerow.scss'

export default function SurfactantsTableRow(props) {
	return (
		<React.Fragment>
			<div className="columns is-centered" style={{ marginTop: '20px' }}>
				<div className="column is-2">{props.RKIPartNo}</div>
				<div className="column is-2">{props.name}</div>

				<div className="column is-2">${Number(props.price_bottle).toFixed(2)}</div>
				<div className="column is-2">${Number(props.price_jug).toFixed(2)}</div>
				<div className="column is-2">
					${Number(props.price_5_gal_pail).toFixed(2)}
				</div>
				<div className="column is-2">
					${Number(props.price_55_gal_drum).toFixed(2)}
				</div>
			</div>
		</React.Fragment>
	);
}
