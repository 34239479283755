import React from 'react';

const PDFButton = (props) => {
	return (
		<div>
			<a className="button is-info" href={props.link}>
				{props.buttonText}
			</a>
		</div>
	);
};

export default PDFButton;
