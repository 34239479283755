import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import ConversionButton from '../../components/dbDisplay/dyes/conversionButton';
import GeneralTable from '../../components/dbDisplay/dyes/generalTable';
import { Link } from 'react-router-dom';

import antifreeze_img from '../../images/dyes/engine/antifreeze1.webp';
import antifreeze_sm_img from '../../images/dyes/engine/antifreeze_sm.webp';
import green_coolant_img from '../../images/dyes/engine/green_coolant.webp';
import quick_lube_img from '../../images/dyes/afct/Quick-Lube.jpg';
import './dyes.scss';
import { useHistory, useLocation } from 'react-router-dom';
import * as RES from '../../helpers/responsive';

export default function EngineCoolant(props) {
	const location = useLocation();
	const history = useHistory();
	const [dyes, setDyes] = useState({});
	const [filteredDyes, setFilteredDyes] = useState({});
	const [searchId, setSearchId] = useState(null);
	const pdf_paths = {
		tds: '/PDF Collection/2000-TDS/',
		sds: '/PDF Collection/SDS Files/2000-SDS/',
	};
	const [newProductModalActive, setNewProductModalActive] = useState(false);

	const colorBox = (shade) => {
		if (shade !== '#FFFFFF') {
			return (
				<div
					className="colorBox-modal is-size-7 has-text-centered"
					style={{ backgroundColor: shade }}
				></div>
			);
		}
	};

	const handleNewProductModalClicked = () => {
		setNewProductModalActive(false);
	};

	const handleNewProductClicked = () => {
		history.push(location.pathname);
		setNewProductModalActive(true);
	};

	const filters = {
		physicalForms: {
			powder: false,
			liquid: false,
			granular: false,
		},
		colors: {
			red: false,
			green: false,
			blue: false,
			yellow: false,
			violet: false,
			orange: false,
		},
	};

	const handleBrowserBackButtonInModal = () => {
		console.log('back');
		if (newProductModalActive) {
			setNewProductModalActive(false);
		}
	};

	// intercept browser back button, so if modal is open we can close it instead
	useEffect(() => {
		window.addEventListener('popstate', handleBrowserBackButtonInModal);
		return () =>
			window.addEventListener('popstate', handleBrowserBackButtonInModal);
	});

	useEffect(() => {
		const ref = firebase.database().ref('dyes/general');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					if (items[item].coolant) {
						newState.push({
							id: item,
							sort_order: items[item].sort_order,
							partNo: items[item].RKIPartNo,
							name: items[item].name,
							descriptor: items[item].descriptor ? items[item].descriptor : '',
							lightShade: items[item].lightShade,
							darkShade: items[item].darkShade,
							physicalType: items[item].physicalType,
							price_1lb_Jar: items[item].price_1lb_Jar,
							price_5lb_Jar: items[item].price_5lb_Jar,
							price_25gal_Pail: items[item].price_25gal_Pail,
							price_small_drum: items[item].price_small_drum,
							price_large_drum: items[item].price_large_drum,
							price_tote: items[item].price_tote ? items[item].price_tote : 0,
							primaryColor: items[item].primaryColor,
							primaryColorOrder: items[item].primColorOrder,
							formerTradeNames: items[item].formerTradeNames,
							fluorescent: items[item].fluorescent
								? items[item].fluorescent
								: false,
							has_link: items[item].has_link,
							link_url: items[item].link_url,
							has_sds: items[item].has_sds ? items[item].has_sds : false,
							sds_filename: items[item].sds_filename
								? items[item].sds_filename
								: false,
							inactive: items[item].inactive ? items[item].inactive : false,
						});
					}
				}
				newState.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
				setDyes(newState);
				setFilteredDyes(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	// filter color and physical forms. object of filter keys passed into function
	const onFilterClicked = (newFilterKeys) => {
		//filter by form first, then by colors.
		if (dyes.length) {
			// if no filters selected show all dyes
			if (
				newFilterKeys['colors'].length < 1 &&
				newFilterKeys['physicalForms'].length < 1
			) {
				setFilteredDyes(dyes);
				return;
			}

			//filter by form
			let filteredPhysicalFormDyes = [];
			if (newFilterKeys['physicalForms'].length < 1) {
				filteredPhysicalFormDyes = [...dyes];
			} else {
				filteredPhysicalFormDyes = dyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['physicalForms'].forEach((filterkey) => {
						if (dye.physicalType.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			}

			// WE NEED TO FILTER THE COLORS FROM THE RESULTS OF THE FORM FILTER, NOT FROM ALL DYES

			// //filter by color
			let filteredColorDyes = [];
			if (newFilterKeys['colors'].length > 0) {
				filteredColorDyes = filteredPhysicalFormDyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['colors'].forEach((filterkey) => {
						if (dye.primaryColor.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			} else {
				filteredColorDyes = filteredPhysicalFormDyes.concat(filteredColorDyes);
			}

			setFilteredDyes(filteredColorDyes);
		}
	};

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return !newProductModalActive ? (
		<>
			<Helmet>
				<title>
					Koch Color - Dyes for Engine Coolant/Antifreeze & Heat Transfer Fluids
				</title>
				<meta
					name="description"
					content="We offer an extensive line of dyes for coloring engine coolant/antifreeze. Many colors are available in both powder and liquid forms. Powder dyes are more economical and are the preferred form for export as freight costs are reduced. Liquid dyes offer the convenience of ease of use and can be added directly to the antifreeze/engine coolant. They also are cleaner to use because they do not have the dust issue associated with a powder dye. We have color matches for the most common commercial products. Call for specific product information and recommended use rates."
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box">
						<div className="title has-text-centered ">
							Dyes for Engine Coolant/Antifreeze & Heat Transfer Fluids
						</div>
						<ConversionButton />

						<section className="section">
							<div className="columns is-centered is-hidden-mobile">
								<div className="column is-3">
									<img className="engine" src={green_coolant_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="engine" src={antifreeze_sm_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="engine" src={antifreeze_img} alt="" />
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								We offer an extensive line of dyes for coloring engine
								coolant/antifreeze. Many colors are available in both powder and
								liquid forms. Powder dyes are more economical and are the
								preferred form for export as freight costs are reduced. Liquid
								dyes offer the convenience of ease of use and can be added
								directly to the antifreeze/engine coolant. They also are cleaner
								to use because they do not have the dust issue associated with a
								powder dye. We have color matches for the most common commercial
								products. Call for specific product information and recommended
								use rates.
							</div>
							<div className="block ml-3">
								<Link to="/dyes/afct">
									<div className="columns">
										<div
											className="column is-one-quarter has-background-link has-text-white coolant-new-product"
											onClick={handleNewProductClicked}
										>
											<p className="has-text-weight-bold">New Product!</p>
											<p className="mt-3 pb-3">
												Coolant/Antifreeze dyes for Vehicle Service Shops
											</p>
											<p className="mt-3">
												Click here for pricing and product information
											</p>
										</div>
										<div
											className="column is-one-fifth has-background-link has-text-white coolant-new-product"
											onClick={handleNewProductClicked}
										>
											<img src={quick_lube_img} alt="" />
										</div>
									</div>
								</Link>
							</div>
						</div>
					</div>

					<GeneralTable
						filteredDyes={filteredDyes}
						filterColors={filters.colors}
						filterPhysicalForms={filters.physicalForms}
						onFilterClicked={(value) => onFilterClicked(value)}
						title="Dyes for Engine Coolant/Antifreeze & Heat Transfer Fluid"
						displayPhysicalForm={true}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						noTDS={false}
					/>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div>
					<GeneralTable
						filteredDyes={filteredDyes}
						filterColors={filters.colors}
						filterPhysicalForms={filters.physicalForms}
						onFilterClicked={(value) => onFilterClicked(value)}
						title="Dyes for Engine Coolant/Antifreeze & Heat Transfer Fluid"
						displayPhysicalForm={true}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						noTDS={false}
					/>
				</div>
			</RES.Handheld>
		</>
	) : (
		<div className="modal is-active" onClick={handleNewProductModalClicked}>
			<div className="modal-background"></div>
			<div className="modal-content has-background-white">
				<div className="p-3">
					<p className="is-size-5 mb-3">Available in FDA Certified version</p>
					<p className="mb-3">
						This product is available in a package of four 1-gallon bottles or
						individual 1-gallon bottles. The primary use is RV anti-freeze and
						potable water freeze protection systems. Not to be used in engine
						coolant.
					</p>
					<div className="p-3" style={{ border: 'solid' }}>
						<div className="columns is-centered  has-text-weight-bold">
							<div className="column is-4"></div>
							<div className="column is-8 has-text-centered">Price per lb</div>
						</div>
						<div className="columns p-  has-text-weight-bold">
							<div className="column is-4"></div>
							<div className="column is-2">4 x 1 gallon bottles</div>
							<div className="column is-2">1-gallon bottle</div>
							<div className="column is-2">Heavy Shade</div>
							<div className="column is-2">Light Shade</div>
						</div>
						<div className="columns p-1">
							<div className="column is-4">Brilliant Blue 2641 Liquid</div>
							<div className="column is-2">$10.25</div>
							<div className="column is-2">$11.25</div>
							<div className="column is-2">{colorBox('#0083c6')}</div>
							<div className="column is-2">{colorBox('#93e3ff')}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
