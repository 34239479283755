import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import TableHeader from '../../components/dbDisplay/aux-products/defoamer/defoamerHeaderRow';
import TableRow from '../../components/dbDisplay/aux-products/defoamer/defoamerRow';
import { useHistory } from 'react-router-dom';

export default function Defoamer(props) {
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const [defoamers, setDefoamers] = useState({});

	useEffect(() => {
		const ref = firebase.database().ref('aux-products/defoamers');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					newState.push({
						id: item,
						RKIPartNo: items[item].RKIPartNo,
						name: items[item].name,
						price_jug: items[item].price_jug,
						price_5_gal_pail: items[item].price_5_gal_pail,
						price_55_gal_drum: items[item].price_55_gal_drum,
						price_bottle: items[item].price_bottle,
					});
				}

				setDefoamers(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<div className="container">
			<Helmet>
				<title>Koch Color - Defoamer</title>
				<meta
					name="description"
					content="Foam can be problematic when working with paints and pigment
						dispersions. Trapped air within paint can cause noticeable surface
						blemishes. Antifoam AFB is an effective way to reduce the formation
						of foam. It can be incorporated into a paint to prevent foam from
						forming. It can also be sprayed onto surface foam as a fine mist
						breaking it down quickly."
				/>
			</Helmet>
			<div className="section box ob-section">
				<div className="title has-text-centered ">Defoamer</div>
				<div className="block is-size-4">Antifoam AFB</div>
				<div className="block">
					<p className="block">
						Foam can be problematic when working with paints and pigment
						dispersions. Trapped air within paint can cause noticeable surface
						blemishes. Antifoam AFB is an effective way to reduce the formation
						of foam. It can be incorporated into a paint to prevent foam from
						forming. It can also be sprayed onto surface foam as a fine mist
						breaking it down quickly.
					</p>
				</div>
			</div>

			<div className="columns">
				<div className="column">
					<div className="box">
						<section className="">
							<TableHeader />
							{defoamers && defoamers.length > 0 ? (
								defoamers.map((item) => {
									if (!searchId) {
										return <TableRow {...item} key={item.id} />;
									} else {
										if (item.id === searchId) {
											return (
												<>
													<TableRow {...item} key={item.id} />
													<button
														className="button is-info is-small"
														onClick={onSearchClearClicked}
													>
														Clear Search Results
													</button>
												</>
											);
										}
										return null;
									}
								})
							) : (
								<p>This table does is empty or does not exist</p>
							)}
						</section>
					</div>
				</div>
			</div>
		</div>
	);
}
