import Home from '../components/Home';
import Contact from '../components/layout/contact/Contact';
// import OpticalBrightners from '../containers/opticalBrightners/OpticalBrightners';

import Acidic from '../containers/dyes/Acidic';
import Alkaline from '../containers/dyes/Alkaline';
import EngineCoolant from '../containers/dyes/EngineCoolant';
import Wash from '../containers/dyes/Wash';
// import FoodCosmetic from '../containers/dyes/FoodCosmetic';
import GeneralUse from '../containers/dyes/GeneralUse';
import Tracer from '../containers/dyes/Tracer';
import NPS_Solvents from '../containers/dyes/NPS_Solvents';
// import HLFPolarSolvents from '../containers/dyes/HLFPolarSolvents'
import FloralAbsorption from '../containers/dyes/FloralAbsorption';
import FloralImmersion from '../containers/dyes/FloralImmersion';
import ConversionTable from '../containers/dyes/ConversionTable';
import AFCT from '../containers/dyes/afct';

import WettinAgents from '../components/wettingAgents/WettingAgents';

// import StandardPigments from '../containers/pigments/StandardPigments'
// import MetallicPearl from '../containers/pigments/MetallicPearl'
// import PremixedMetallic from '../containers/cb/PremixedMetallic'
import MossRevive from '../containers/mossRevive/MossRevive';
// import MetallicPigmentsPaste from '../containers/metallic-pigments-paint/MetallicPigmentsPaint';

// import Glitter from '../containers/glitter/Glitter'
// import GlitterSpecs from '../components/dbDisplay/glitter/glitterSpecs'

import AllFragrances from '../containers/fragrances/AllFragrances';
import Botanical from '../containers/fragrances/Botanical';
import Cinnamon from '../containers/fragrances/Cinnamon_Spice';
import Citrus from '../containers/fragrances/Citrus';
import Food from '../containers/fragrances/Food';
import Fresh from '../containers/fragrances/Fresh';
import Fruit from '../containers/fragrances/Fruit';
import Odor from '../containers/fragrances/Odor';

// import AdhesiveBinders from '../containers/auxilliaryProducts/AdhesiveBinders'
import Defoamer from '../containers/auxilliaryProducts/Defoamer';
import Glycols from '../containers/auxilliaryProducts/Glycols';
import Preservatives from '../containers/auxilliaryProducts/Preservatives';
import Surfactants from '../containers/auxilliaryProducts/Surfactants';
import Error404 from '../components/Error404';

const routes = [
	{
		path: '/',
		exact: true,
		component: Home,
	},
	{
		path: '/contact',
		exact: true,
		component: Contact,
	},
	{
		exact: true,
		path: '/dyes/acidic',
		component: Acidic,
	},
	{
		exact: true,
		path: '/dyes/alkaline',
		component: Alkaline,
	},
	{
		exact: true,
		path: '/dyes/coolant',
		component: EngineCoolant,
	},
	{
		exact: true,
		path: '/dyes/wash',
		component: Wash,
	},
	// {
	// 	exact: true,
	// 	path: '/dyes/food-cosmetic',
	// 	component: FoodCosmetic,
	// },
	{
		exact: true,
		path: '/dyes/general-use',
		component: GeneralUse,
	},
	{
		exact: true,
		path: '/dyes/tracer',
		component: Tracer,
	},
	{
		exact: true,
		path: '/dyes/non-polar-solvents',
		component: NPS_Solvents,
	},
	// {
	// 	exact: true,
	// 	path: '/dyes/polar-solvents',
	// 	component: HLFPolarSolvents,
	// },
	{
		exact: true,
		path: '/dyes/floral-and-craft-absorption',
		component: FloralAbsorption,
	},
	{
		exact: true,
		path: '/dyes/floral-and-craft-immersion',
		component: FloralImmersion,
	},
	{
		exact: true,
		path: '/dyes/floral-and-craft-absorption/wetting-agents',
		component: WettinAgents,
	},
	{
		exact: true,
		path: '/dyes/conversions',
		component: ConversionTable,
	},
	{
		exact: true,
		path: '/dyes/afct',
		component: AFCT,
	},
	// {
	// 	exact: true,
	// 	path: '/pigments/standard-pigments',
	// 	component: StandardPigments,
	// },
	// {
	// 	exact: true,
	// 	path: '/pigments/premixed-metallic',
	// 	component: PremixedMetallic,
	// },
	// {
	// 	exact: true,
	// 	path: '/pigments/metallic-pigments-paste',
	// 	component: MetallicPigmentsPaste,
	// },
	{
		exact: true,
		path: '/moss-revive',
		component: MossRevive,
	},
	// {
	// 	exact: true,
	// 	path: '/glitter',
	// 	component: Glitter,
	// },
	// {
	// 	exact: true,
	// 	path: '/glitter/specs',
	// 	component: GlitterSpecs,
	// },
	{
		exact: true,
		path: '/fragrances/all',
		component: AllFragrances,
	},
	{
		exact: true,
		path: '/fragrances/botanical',
		component: Botanical,
	},
	{
		exact: true,
		path: '/fragrances/cinnamon-spice',
		component: Cinnamon,
	},
	{
		exact: true,
		path: '/fragrances/citrus',
		component: Citrus,
	},
	{
		exact: true,
		path: '/fragrances/fresh',
		component: Fresh,
	},
	{
		exact: true,
		path: '/fragrances/food',
		component: Food,
	},
	{
		exact: true,
		path: '/fragrances/fruit',
		component: Fruit,
	},
	{
		exact: true,
		path: '/fragrances/odor-masking',
		component: Odor,
	},
	// {
	// 	exact: true,
	// 	path: '/optical-brighteners',
	// 	component: OpticalBrightners,
	// },
	// {
	// 	exact: true,
	// 	path: '/auxilliary-products/adhesive-binders',
	// 	component: AdhesiveBinders,
	// },
	{
		exact: true,
		path: '/auxilliary-products/defoamer',
		component: Defoamer,
	},
	{
		exact: true,
		path: '/auxilliary-products/glycols',
		component: Glycols,
	},
	{
		exact: true,
		path: '/auxilliary-products/preservatives',
		component: Preservatives,
	},
	{
		exact: true,
		path: '/auxilliary-products/surfactants',
		component: Surfactants,
	},

	// {
	// 	path: '/sitemap',
	// 	component: Sitemap
	// },
	{
		component: Error404,
	},
];

export default routes;
