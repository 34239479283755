import React from 'react';

export default function PreservativesTableRow(props) {
	return (
		<React.Fragment>
			<div className="columns is-centered" style={{ marginTop: '20px' }}>
				<div className="column is-2">{props.RKIPartNo}</div>
				<div className="column is-2">{props.name}</div>

				<div className="column is-2">${Number(props.price_1lb_jar).toFixed(2)}</div>
				<div className="column is-2">${Number(props.price_5lb_jar).toFixed(2)}</div>
				<div className="column is-2">
					${Number(props.price_25_gal_pail).toFixed(2)}
				</div>
				<div className="column is-2">
					${Number(props.price_55_gal_box).toFixed(2)}
				</div>
			</div>
		</React.Fragment>
	);
}
