import React from 'react';
import { titleCase } from 'title-case';
import sds_icon from '../../../images/sds-icon.png';

import * as RES from '../../../helpers/responsive';

export default function FragranceTableRow(props) {
	return (
		<>
			<RES.Default>
				<div
					id={props.id}
					className="columns is-centered"
					style={{ marginTop: '20px' }}
				>
					<div className="column is-5">
						<div className="columns is-mobile">
							<div className="column is-3">{props.partNo}</div>
							<div className="column is-6">
								{titleCase(props.name.toLowerCase())}
							</div>
							<div className="column is-3">
								{props.has_sds ? (
									<a
										href={
											process.env.PUBLIC_URL +
											props.pdf_paths.sds +
											props.sds_filename
										}
										target="_blank"
										rel="noreferrer"
									>
										<img src={sds_icon} alt="SDS Icon" />
									</a>
								) : (
									<div>N/A</div>
								)}
							</div>
						</div>
					</div>
					<div className="column is-7">
						<div className="columns is-mobile  is-size-7-mobile">
							<div className="column is-3">
								${Number(props.price_quart).toFixed(2)}
							</div>
							<div className="column is-3">
								${Number(props.price_gallon).toFixed(2)}
							</div>
							<div className="column is-3">
								${Number(props.price_5_gallons).toFixed(2)}
							</div>
							<div className="column is-3">
								${Number(props.price_50_gallons).toFixed(2)}
							</div>
						</div>
					</div>
				</div>
			</RES.Default>
			<RES.Mobile>
				<div
					id={props.id}
					className="columns is-centered ml-1 has-background-white"
					style={{ marginTop: '20px' }}
				>
					<div className="column is-5">
						<div className="columns is-mobile is-size-7-mobile">
							<div className="column is-3">{props.partNo}</div>
							<div className="column is-6">
								{titleCase(props.name.toLowerCase())}
							</div>
							<div className="column is-3">
								{props.has_sds ? (
									<a
										href={
											process.env.PUBLIC_URL +
											props.pdf_paths.sds +
											props.sds_filename
										}
										target="_blank"
										rel="noreferrer"
									>
										<img src={sds_icon} alt="SDS Icon" width="20" />
									</a>
								) : (
									<div>N/A</div>
								)}
							</div>
						</div>
					</div>
					<div className="column is-4">
						<div className="columns is-mobile  is-size-7-mobile">
							<div className="column is-3">
								${Number(props.price_quart).toFixed(2)}
							</div>
							<div className="column is-3">
								${Number(props.price_gallon).toFixed(2)}
							</div>
							<div className="column is-3">
								${Number(props.price_5_gallons).toFixed(2)}
							</div>
							<div className="column is-3">
								${Number(props.price_50_gallons).toFixed(2)}
							</div>
						</div>
					</div>
					<div className="columns header-bottom-border has-background-link-light">
						<div className="column"></div>
					</div>
				</div>
			</RES.Mobile>
		</>
	);
}
