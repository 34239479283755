import React from 'react';
import { Link } from 'react-router-dom';

export default function WettingAgents() {
	return (
		<div>
			<div className="box">
				<div className="title has-text-centered ">Wetting Agents</div>

				<div className="section">
					<div className="block is-size-4">Description</div>
					<div className="block">
						Wetting agents (also known as surfactants) are used to lower the
						surface tension of water, dye & preservative solutions, making
						absorption of the solution easier & more uniform. Wetting agents are
						used in both systemic & immersion applications. They are used at the
						rate of 0.1% by weight of the solution to which they are being
						added. This is equivalent to 0.12 fluid ounces of wetting agent
						(approximately one teaspoon) per gallon of solution or one gram
						(approximately one milliliter) of wetting agent per liter of
						solution. We have two wetting agents for the following application.
					</div>
					<div className="block is-size-4">Systemic Applications</div>
					<div className="block">
						<p className="block">
							(One quart of TW-20 is sufficient for 12 pounds of systemic dye)
						</p>
						<p className="block">
							<strong>TW-20 Systemic Wetting Agent</strong>
						</p>
						<hr />
					</div>

					<div className="columns">
						<div className="column is-6">
							<p className="block">Quart $20.45/ea</p>
							<p className="block">Single Gallon $50.69/ea</p>
							<p className="block">4X1 Gallon Case $42.48/gal</p>
							<p className="block">5-Gallon Pail $38.39/gal</p>
						</div>
						<div className="column is-6">
							<p className="block">
								<strong>Applications</strong>
							</p>
							<p className="block">
								Dyeing fresh cut flowers such as carnations.
							</p>
							<p className="block">
								Systemically preserving flowers and foliage with glycerine.
							</p>
						</div>
					</div>

					<div className="block is-size-4">
						Immersion Dyeing & Preserving Applications
					</div>
					<div className="block">
						<p className="block">
							<strong>NP-9 Surfactant</strong>
						</p>
						<hr />
					</div>
					<div className="block">
						<p className="block">
							<strong>
								(NP-9 Surfactant is DISCONTINUED) Replaced with TW-80 Surfactant
							</strong>
						</p>
						<hr />
					</div>
					<div className="block">
						<p className="block">
							<strong>TW-80 Surfactant</strong>
						</p>
						<hr />
					</div>

					<div className="columns">
						<div className="column is-6">
							<p className="block">Quart $20.45/ea</p>
							<p className="block">Single Gallon $50.69/ea</p>
							<p className="block">4X1 Gallon Case $42.48/gal</p>
							<p className="block">5-Gallon Pail $38.39/gal</p>
						</div>
						<div className="column is-6">
							<p className="block">
								<strong>Applications</strong>
							</p>
							<p className="block">
								Used as a wetting agent in immersion (dip) dyeing fresh flowers
								such as daisies. TW-80 can also be used with died flowers.
							</p>
						</div>
					</div>
					<div className="has-text-centered"><Link
						className="button is-success"
						to="/dyes/floral-and-craft-absorption"
					>
						Back to Absorption Dyes
					</Link></div>
					
				</div>
			</div>
		</div>
	);
}
