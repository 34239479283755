import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import SolventTable from '../../components/dbDisplay/dyes/SolventTable';
import { useHistory } from 'react-router-dom';

import bottles_img from '../../images/dyes/nps/bottles.jpg';
import four_color_img from '../../images/dyes/nps/four-color.jpg';
import liquid_colors_img from '../../images/dyes/nps/liquidcolor.jpg';
import testtubes_img from '../../images/dyes/nps/testtubes.jpg';
import * as RES from '../../helpers/responsive';


export default function NPS_Solvents(props) {

	const history = useHistory();
	const [dyes, setDyes] = useState({});
	const [searchId, setSearchId] = useState(null);

	const pdf_paths = {
		tds: '/PDF Collection/7000-TDS/',
		sds: '/PDF Collection/SDS Files/7000-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/nps7000');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();
				let newState = [];
				for (let item in items) {
					newState.push({
						id: item,
						partNo: items[item].RKIPartNo,
						sort_order: items[item].sort_order,
						name: items[item].name,
						fluorescent: items[item].fluorescent,
						descriptor: items[item].descriptor ? items[item].descriptor : '',
						formerTradeNames: items[item].formerTradeNames
							? items[item].formerTradeNames
							: '',
						lightShade: items[item].lightShade,
						darkShade: items[item].darkShade,
						powder: items[item].powder,
						price_1lb_Jar: items[item].price_1lb_Jar,
						price_5lb_Jar: items[item].price_5lb_Jar,
						price_25gal_Pail: items[item].price_25lb_Pail,
						primaryColor: items[item].primaryColor,
						has_link: items[item].has_link ? items[item].has_link : false,
						link_url: items[item].link_url ? items[item].link_url : '',
						has_sds: items[item].has_sds ? items[item].has_sds : false,
						sds_filename: items[item].sds_filename
							? items[item].sds_filename
							: false,
						inactive: items[item].inactive ? items[item].inactive : false,
					});
				}
				newState.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
				setDyes(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>
					Koch Color - Dyes for Hydrocarbons, Greases, Oils, Waxes and Non-Polar
					Solvents
				</title>
				<meta
					name="description"
					content="Robert Koch Industries' line of solvent dyes are soluble in oils, fats, waxes, resins, hydrocarbons, non-polar solvents, and to a lesser extent, alcohols. Solvent Dyes are insoluble in water."
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box">
						{/* <div className="title has-text-centered ">
					NPS Solvent Dye Selection
				</div> */}
						<div className="title  has-text-centered">
							Dyes for Hydrocarbons, Greases, Oils, Waxes and Non-Polar Solvents
						</div>
						<section className="container">
							<div className="columns">
								<div className="column is-3">
									<img className="image ob" src={bottles_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={four_color_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={liquid_colors_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={testtubes_img} alt="" />
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								Robert Koch Industries' line of solvent dyes are soluble in
								oils, fats, waxes, resins, hydrocarbons, non-polar solvents, and
								to a lesser extent, alcohols. Solvent Dyes are insoluble in
								water.
							</div>
							<div className="block is-size-4">Application</div>
							<div className="content">
								Solvent dyes are widely used in coloring petroleum derivatives,
								wax products, candles, fragrance oils, and wood impregnations.
								They are also used for the production of smokes, crack
								detectors, and fluorescent pigments.
							</div>
						</div>
					</div>

					<div className="box">
						<section>
							<SolventTable
								filter={dyes}
								onFilterClicked={() => {}}
								title="Dyes for Hydrocarbons, Greases, Oils, Waxes and
					Non-Polar Solvents"
								nps={true}
								onSearchClear={onSearchClearClicked}
								searchId={searchId}
								noTDS={false}
								noSDS={false}
								// displayPhysicalForm={false}
								pdf_paths={pdf_paths}
							/>
						</section>
					</div>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<SolventTable
					filter={dyes}
					onFilterClicked={() => {}}
					title="Dyes for Hydrocarbons, Greases, Oils, Waxes and
					Non-Polar Solvents"
					nps={true}
					onSearchClear={onSearchClearClicked}
					searchId={searchId}
					noTDS={false}
					noSDS={false}
					// displayPhysicalForm={false}
					pdf_paths={pdf_paths}
				/>
			</RES.Handheld>
		</>
	);
}
