import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import GeneralTable from '../../components/dbDisplay/dyes/generalTable';
import { useHistory } from 'react-router-dom';
import PDFButton from '../../components/dbDisplay/PDFButton';

import brooms_img from '../../images/dyes/immersion/dyed-brooms.jpg';
import gourds_img from '../../images/dyes/immersion/dyed-gourds.jpg';
import woodcurls_img from '../../images/dyes/immersion/dyed-woodcurls.jpg';
import orange_img from '../../images/dyes/immersion/orange.jpg';
import * as RES from '../../helpers/responsive';

export default function FloralImmersion(props) {
	const history = useHistory();
	const [dyes, setDyes] = useState({});
	const [filteredDyes, setFilteredDyes] = useState({});
	const [searchId, setSearchId] = useState(null);

	const filters = {
		physicalForms: {
			powder: false,
			liquid: false,
			granular: false,
		},
		colors: {
			red: false,
			green: false,
			blue: false,
			yellow: false,
			orange: false,
			violet: false,
			brown: false,
			black: false,
		},
	};

	const pdf_paths = {
		tds: '/PDF Collection/8000-TDS/',
		sds: '/PDF Collection/SDS Files/8000-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/immersion8000');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					newState.push({
						id: item,
						sort_order: items[item].sort_order,
						partNo: items[item].RKIPartNo,
						name: items[item].name,
						lightShade: items[item].lightShade,
						darkShade: items[item].darkShade,
						primaryColor: items[item].primaryColor,
						price_1lb_Jar: items[item].price_1lb_Jar,
						price_5lb_Jar: items[item].price_5lb_Jar,
						price_25gal_Pail: items[item].price_25lb_Pail,
						// formerTradeNames: items[item].formerTradeNames,
						fluorescent: items[item].fluorescent
							? items[item].fluorescent
							: false,
						has_link: items[item].has_link ? items[item].has_link : false,
						link_url: items[item].link_url ? items[item].link_url : '',
						has_sds: items[item].has_sds ? items[item].has_sds : false,
						sds_filename: items[item].sds_filename
							? items[item].sds_filename
							: false,
						inactive: items[item].inactive ? items[item].inactive : false,
					});
				}
				newState.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
				setDyes(newState);
				setFilteredDyes(newState);
			}
		});
	}, []);

	// filter color and physical forms. object of filter keys passed into function
	const onFilterClicked = (newFilterKeys) => {
		//filter by form first, then by colors.
		if (dyes.length) {
			// if no filters selected show all dyes
			if (
				newFilterKeys['colors'].length < 1 &&
				newFilterKeys['physicalForms'].length < 1
			) {
				setFilteredDyes(dyes);
				return;
			}

			//filter by form
			let filteredPhysicalFormDyes = [];
			if (newFilterKeys['physicalForms'].length < 1) {
				filteredPhysicalFormDyes = [...dyes];
			} else {
				filteredPhysicalFormDyes = dyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['physicalForms'].forEach((filterkey) => {
						if (dye.physicalType.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			}

			// WE NEED TO FILTER THE COLORS FROM THE RESULTS OF THE FORM FILTER, NOT FROM ALL DYES

			// //filter by color
			let filteredColorDyes = [];
			if (newFilterKeys['colors'].length > 0) {
				filteredColorDyes = filteredPhysicalFormDyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['colors'].forEach((filterkey) => {
						if (dye.primaryColor.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			} else {
				filteredColorDyes = filteredPhysicalFormDyes.concat(filteredColorDyes);
			}

			setFilteredDyes(filteredColorDyes);
		}
	};

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>Koch Color - Immersion Dyes for Dried Flowers & Foliage</title>
				<meta
					name="description"
					content="Basic dyes are cationic (positively charged) dyes with excellent solubility in water and moderate solubility in many glycols and alcohols. These dyes are high in color strength and have characteristically brilliant color, including many beautiful fluorescent shades. Basic dyes are widely used in many applications. Basic dyes are well suited for immersion dyeing of many bast fibers, dried floral and craft materials. This includes raffia, jute, sisal, straw, broom corn, flowers, grasses, pods, berries, moss, lichen, wood curls/chips, and excelsior."
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box">
						<div className="title has-text-centered ">
							Immersion Dyes for Dried Flowers {'&'} Foliage
						</div>
						<div className="subtitle has-text-centered ">
							For Dyeing Dried Flowers, Foliage and Craft Materials
						</div>
						<section className="container">
							<div className="columns">
								<div className="column is-3">
									<img className="image ob" src={brooms_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={gourds_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={orange_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={woodcurls_img} alt="" />
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								Basic dyes are cationic (positively charged) dyes with excellent
								solubility in water and moderate solubility in many glycols and
								alcohols. These dyes are high in color strength and have
								characteristically brilliant color, including many beautiful
								fluorescent shades. Basic dyes are widely used in many
								applications. Basic dyes are well suited for immersion dyeing of
								many bast fibers, dried floral and craft materials. This
								includes raffia, jute, sisal, straw, broom corn, flowers,
								grasses, pods, berries, moss, lichen, wood curls/chips, and
								excelsior.
							</div>

							<div className="block is-size-4">Technical Bulletins</div>
							<div className="block">
								<div className="bbuttons">
									<p className="">Product Reference Sheet #200</p>
									<PDFButton
										className="block mt-6"
										buttonText="DYEING FLORAL & CRAFT PRODUCTS BY IMMERSION WITH BASIC DYES"
										link={
											process.env.PUBLIC_URL +
											'/PDF Collection/technical-bulletins/ref-200-english-web-v2.pdf'
										}
									></PDFButton>
									<p className="mt-4">Boletín Técnico #200</p>
									<PDFButton
										className="block mt-6"
										buttonText="TEÑIDO DE PRODUCTOS FLORALES & DE ARTESANÍAS POR MEDIO DE LA
								INMERSIÓN EN TINTURAS BÁSICAS"
										link={
											process.env.PUBLIC_URL +
											'/PDF Collection/technical-bulletins/ref-200-espanol-web-v2.pdf'
										}
									></PDFButton>
									<p className="mt-4">Product Reference Sheet #201</p>

									<PDFButton
										className="block mt-6"
										buttonText="DYEING FRESH CUT FOLIAGE BY IMMERSION WITH BASIC DYES"
										link={
											process.env.PUBLIC_URL +
											'/PDF Collection/technical-bulletins/ref-201-english-web-v2.pdf'
										}
									></PDFButton>

									<p className="mt-4">Boletín Técnico #201</p>

									<PDFButton
										className="block mt-6"
										buttonText="TEÑIDO DEFOLLAJE FRESCA CORTADA POR MEDIO DE LA INMERSIÓN EN
								TINTURAS BASICÁS"
										link={
											process.env.PUBLIC_URL +
											'/PDF Collection/technical-bulletins/ref-201-espanol-web-v2.pdf'
										}
									></PDFButton>
								</div>
							</div>
						</div>
					</div>
					<GeneralTable
						filteredDyes={filteredDyes}
						filterColors={filters.colors}
						displayPhysicalForm={false}
						onFilterClicked={(value) => onFilterClicked(value)}
						title="Water Soluble Basic Dyes"
						noTDS={false}
						noSDS={false}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						noLiquid={true}
					/>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<GeneralTable
					filteredDyes={filteredDyes}
					filterColors={filters.colors}
					displayPhysicalForm={false}
					onFilterClicked={(value) => onFilterClicked(value)}
					title="Water Soluble Basic Dyes"
					noTDS={false}
					noSDS={false}
					onSearchClear={onSearchClearClicked}
					searchId={searchId}
					pdf_paths={pdf_paths}
					noLiquid={true}
				/>
			</RES.Handheld>
		</>
	);
}
