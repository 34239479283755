import routes from './routes'
import {Route, Switch} from 'react-router-dom'

export default function RenderRoutes() {
	return (
		<Switch>
			{routes.map((route, i) => {
				return (
					<RouteWithSubRoutes
						path={route.path}
						exact={route.exact}
						component={route.component}
						key={i}
					/>
				);
			})}
		</Switch>
	);
}

const RouteWithSubRoutes = (route) => {
	return (
		<Route
			path={route.path}
			exact={route.exact}
			component={route.component}
			// render={props => <route.component {...props} routes={route.routes} />}
		/>
	);
};
