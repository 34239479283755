import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import TableHeader from '../../components/dbDisplay/fragrances/fragranceHeaderRow';
import TableRow from '../../components/dbDisplay/fragrances/fragranceTableRow';
import { useHistory } from 'react-router-dom';
import Sticky from 'react-stickynode';
import * as RES from '../../helpers/responsive'


export default function AllFragrances(props) {
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const [fragrance, setFragrance] = useState({});

	const pdf_paths = {
		tds: '',
		sds: '/PDF Collection/SDS Files/Fragrance-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('fragrances');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					newState.push({
						id: item,
						botanical: items[item].botanical,
						cinnamon: items[item].cinnamon_spice,
						citrus: items[item].citrus,
						food: items[item].citrus,
						fresh: items[item].fresh_clean,
						fruit: items[item].fruit,
						odor: items[item].odor,
						date: items[item].date,
						notes: items[item].notes,
						partNo: items[item].RKIPartNo,
						name: items[item].name,
						price_5_gallons: items[item].price_5_gallons,
						price_gallon: items[item].price_gallon,
						price_quart: items[item].price_quart,
						price_50_gallons: items[item].price_50_gallons,
						has_link: items[item].has_link ? items[item].has_link : false,
						link_url: items[item].link_url ? items[item].link_url : '',
						has_sds: items[item].has_sds ? items[item].has_sds : false,
						sds_filename: items[item].sds_filename
							? items[item].sds_filename
							: false,
					});
				}
				newState.sort((a, b) => (a.name > b.name ? 1 : -1));

				setFragrance(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<RES.Default>
				<div className="section fragrance-section">
					<Helmet>
						<title>Koch Color - Fragrances</title>
						<meta name="description" content="fragrances" />
					</Helmet>
					<div className="box">
						<section className="">
							<Sticky enabled={true} top={100}>
								<TableHeader title="All Fragrances (A-Z)" />
							</Sticky>
							{fragrance && fragrance.length > 0 ? (
								fragrance.map((item) => {
									if (!item.isPowder) {
										if (!searchId) {
											return (
												<TableRow
													{...item}
													key={item.id}
													pdf_paths={pdf_paths}
												/>
											);
										} else {
											if (item.id === searchId) {
												return (
													<>
														<TableRow
															{...item}
															key={item.id}
															pdf_paths={pdf_paths}
														/>
														<button
															className="button is-info is-small"
															onClick={onSearchClearClicked}
														>
															Clear Search Results
														</button>
													</>
												);
											}
											return null;
										}
									}
									return null;
								})
							) : (
								<div style={{ marginTop: '20px' }}>
									<progress className="progress is-small is-primary" max="100">
										15%
									</progress>
								</div>
							)}
						</section>
					</div>
				</div>
			</RES.Default>
			<RES.Mobile>
				<div className="">
					<section className="has-background-white">
						<Sticky enabled={true} top={100}>
							<TableHeader title="All Fragrances (A-Z)" />
						</Sticky>
						{fragrance && fragrance.length > 0 ? (
							fragrance.map((item) => {
								if (!searchId) {
									return (
										<>
											<TableRow {...item} key={item.id} pdf_paths={pdf_paths} />
										</>
									);
								} else {
									if (item.id === searchId) {
										return (
											<>
												<TableRow
													{...item}
													key={item.id}
													pdf_paths={pdf_paths}
												/>
												<button
													className="button is-info is-small"
													onClick={onSearchClearClicked}
												>
													Clear Search Results
												</button>
											</>
										);
									}
									return null;
								}
							})
						) : (
							<div style={{ marginTop: '20px' }}>
								<progress className="progress is-small is-primary" max="100">
									15%
								</progress>
							</div>
						)}
					</section>
				</div>
			</RES.Mobile>
		</>
	);
}
