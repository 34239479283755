import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import ConversionButton from '../../components/dbDisplay/dyes/conversionButton';

import GeneralTable from '../../components/dbDisplay/dyes/generalTable';

import powder from '../../images/dyes/general/powder.jpg';
import jugs from '../../images/dyes/general/jugs.png';
import highlighter from '../../images/dyes/general/highlighter.png';
import soap from '../../images/dyes/general/soap.png';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as RES from '../../helpers/responsive';

export default function GeneralUse(props) {
	const history = useHistory();
	const [dyes, setDyes] = useState({});
	const [filteredDyes, setFilteredDyes] = useState({});
	const [searchId, setSearchId] = useState(null);

	const filters = {
		physicalForms: {
			powder: false,
			liquid: false,
			granular: false,
		},
		colors: {
			red: false,
			green: false,
			blue: false,
			yellow: false,
			orange: false,
			violet: false,
			brown: false,
			black: false,
		},
	};

	const pdf_paths = {
		tds: '/PDF Collection/2000-TDS/',
		sds: '/PDF Collection/SDS Files/2000-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/general');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					if (items[item].general && !items[item].inactive) {
						newState.push({
							id: item,
							partNo: items[item].RKIPartNo,
							name: items[item].name,
							descriptor: items[item].descriptor ? items[item].descriptor : '',
							lightShade: items[item].lightShade,
							darkShade: items[item].darkShade,
							physicalType: items[item].physicalType,
							price_1lb_Jar: items[item].price_1lb_Jar,
							price_5lb_Jar: items[item].price_5lb_Jar,
							price_25gal_Pail: items[item].price_25gal_Pail,
							price_small_drum: items[item].price_small_drum,
							price_large_drum: items[item].price_large_drum,
							price_tote: items[item].price_tote ? items[item].price_tote : 0,
							primaryColor: items[item].primaryColor,
							sort_order: items[item].sort_order,
							formerTradeNames: items[item].formerTradeNames,
							fluorescent: items[item].fluorescent
								? items[item].fluorescent
								: false,
							has_link: items[item].has_link,
							link_url: items[item].link_url,
							has_sds: items[item].has_sds ? items[item].has_sds : false,
							sds_filename: items[item].sds_filename
								? items[item].sds_filename
								: false,
							has_sds_es: items[item].has_sds_es
								? items[item].has_sds_es
								: false,
							sds_filename_es: items[item].sds_filename_es
								? items[item].sds_filename_es
								: false,
							inactive: items[item].inactive ? items[item].inactive : false,
						});
					}
				}
				newState.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
				setDyes(newState);
				setFilteredDyes(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	// filter color and physical forms. object of filter keys passed into function
	const onFilterClicked = (newFilterKeys) => {
		console.log(newFilterKeys);
		//filter by form first, then by colors.
		if (dyes.length) {
			// if no filters selected show all dyes
			if (
				newFilterKeys['colors'].length < 1 &&
				newFilterKeys['physicalForms'].length < 1
			) {
				setFilteredDyes(dyes);
				return;
			}

			//filter by form
			let filteredPhysicalFormDyes = [];
			if (newFilterKeys['physicalForms'].length < 1) {
				filteredPhysicalFormDyes = [...dyes];
			} else {
				filteredPhysicalFormDyes = dyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['physicalForms'].forEach((filterkey) => {
						if (dye.physicalType.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			}

			// WE NEED TO FILTER THE COLORS FROM THE RESULTS OF THE FORM FILTER, NOT FROM ALL DYES

			// //filter by color
			let filteredColorDyes = [];
			if (newFilterKeys['colors'].length > 0) {
				filteredColorDyes = filteredPhysicalFormDyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['colors'].forEach((filterkey) => {
						if (dye.primaryColor.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			} else {
				filteredColorDyes = filteredPhysicalFormDyes.concat(filteredColorDyes);
			}

			setFilteredDyes(filteredColorDyes);
		}
	};

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>Koch Color - General Use Dyes</title>
				<meta
					name="description"
					content="The dyes listed here are water soluble anionic dyes selected from the acid, direct and reactive dye classes. They have good solubility in water and moderate solubility in glycols and alcohols. The suggested pH range for dyes listed herein is 4-9. We offer other specialty dyes for high pH and low pH applications."
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box is-hidden-mobile">
						<div className="title has-text-centered ">Dyes - General Use</div>
						<div className="subtitle  has-text-centered">
							Inks, Highlighters, Cleaning Products, (pH 4-9)
						</div>

						<ConversionButton />

						<section className="container">
							<div className="columns is-hidden-mobile">
								<div className="column is-3">
									<img className="image ob" src={powder} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={jugs} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={highlighter} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={soap} alt="" />
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								The dyes listed here are water soluble anionic dyes selected
								from the acid, direct and reactive dye classes. They have good
								solubility in water and moderate solubility in glycols and
								alcohols. The suggested pH range for dyes listed herein is 4-9.
								We offer other specialty dyes for high pH and low pH
								applications.
							</div>
							<div className="block is-size-4">Applications</div>
							<div className="content">
								There are a number of applications for these dyes, such as :
								<ul>
									<li>
										<span className="has-text-weight-bold">
											Industrial & Household Products
										</span>{' '}
										- Detergents & Cleaners{' '}
									</li>
									<li>
										<span className="has-text-weight-bold">Agriculture</span> -
										Water and fertilizer tracing, flower dyeing{' '}
									</li>
									<li>
										<span className="has-text-weight-bold">Wood</span> -
										Excelsior and paper dyeing{' '}
									</li>
									<li>
										<span className="has-text-weight-bold">Craft Products</span>{' '}
										- Liquid potpourri, bazaar dyes, ink stamps
									</li>
								</ul>
							</div>
						</div>
					</div>
					<GeneralTable
						filteredDyes={filteredDyes}
						filterColors={filters.colors}
						filterPhysicalForms={filters.physicalForms}
						onFilterClicked={(v) => onFilterClicked(v)}
						title="General Use"
						displayPhysicalForm={true}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						noTDS={false}
						noSDS={false}
					/>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div className="">
					<GeneralTable
						filteredDyes={filteredDyes}
						filterColors={filters.colors}
						filterPhysicalForms={filters.physicalForms}
						// onFilterClicked={(value) => onFilterClicked(value, value1)}
						title="General Use Dyes"
						displayPhysicalForm={true}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						noTDS={false}
						noSDS={false}
					/>
				</div>
			</RES.Handheld>
		</>
	);
}
