import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import TableHeader from '../../components/dbDisplay/dyes/afct/tableHeader';
import TableRow from '../../components/dbDisplay/dyes/afct/tableRow';
import * as RES from '../../helpers/responsive';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import Sticky from 'react-stickynode';
import afct_bottles_img from '../../images/dyes/afct/afct bottles1.webp';
import anitifreeze_img from '../../images/dyes/afct/antirfreeze.webp';
import afct1_img from '../../images/dyes/afct/afct 1.webp';

export default function AFCT(props) {
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const [AFCT, setAFCT] = useState({});
	const [AFCTPriceLabels, setAFCTPriceLabels] = useState({});

	const pdf_paths = {
		tds: '',
		sds: '/PDF Collection/SDS Files/AFCT/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/afct/products');

		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();
				let newState = [];
				for (let item in items) {
					newState.push({
						id: item,
						partNo: items[item].partNo,
						name: items[item].name,
						price1: items[item].price1,
						price2: items[item].price2,
						price3: items[item].price3,
						shade: items[item].shade,
						has_sds: items[item].has_sds,
						sds_filename: items[item].sds_filename,
					});
				}
				// newState.sort((a, b) => (a.sort - b.sort));
				setAFCT(newState);
			}
		});
	}, []);

	useEffect(() => {
		const db = firebase.database();
		const refLabels = db.ref('dyes/afct/labels');

		const unsubscribe = refLabels.on('value', (snapshot) => {
			if (snapshot) {
				setAFCTPriceLabels(snapshot.val());
			}
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>Koch Color - Antifreeze Color Treatment (AFCT-55) Dyes</title>
				<meta
					name="description"
					content="Our Antifreeze Color Treatment Bottles are designed to help color small batches of clear antifreeze/engine coolant quickly and easily.  Each 200-ml bottle contains sufficient dye to treat up to 55 gallons.  Our usage table shows the quantity needed for smaller batches like quarts and gallons. We have a wide color range that matches up to most commercial products. Bottles can be purchased individually or by the case and are perfect for express car care centers, auto repair shops and coolant recyclers. "
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box">
						<div className="title has-text-centered ">
							Antifreeze Color Treatment (AFCT-55) Dyes
						</div>
						<div className="title is-size-4 has-text-centered">
							Dyes for Vehicle Service Shops
						</div>
						<section>
							<div className="columns is-mobile is-centered section">
								<div className="column is-one-quarter has-text-centered">
									<img src={anitifreeze_img} alt="afct bottles" />
								</div>
								<div className="column is-1 has-text-centered"></div>
								<div className="column is-one-quarter has-text-centered">
									<img src={afct_bottles_img} alt="afct bottles" />
								</div>
								<div className="column is-1 has-text-centered"></div>
								<div className="column is-one-quarter has-text-centered">
									<img src={afct1_img} alt="afct bottles" />
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								Our Antifreeze Color Treatment Bottles are designed to help
								color small batches of clear antifreeze/engine coolant quickly
								and easily. Each 200-ml bottle contains sufficient dye to treat
								up to 55 gallons. Our usage table shows the quantity needed for
								smaller batches like quarts and gallons. We have a wide color
								range that matches up to most commercial products. Bottles can
								be purchased individually or by the case and are perfect for
								express car care centers, auto repair shops and coolant
								recyclers. Download the use rate product sheet{' '}
								<a
									className=""
									target="_blank"
									rel="noreferrer"
									href={
										process.env.PUBLIC_URL +
										'/PDF Collection/afct/afct-55-urt-v19.pdf'
									}
								>
									here.
								</a>
							</div>
						</div>
					</div>
					<div className="box">
						<section className="">
							<Sticky enabled={true} top={115}>
								<TableHeader
									title="Antifreeze Color Treatment (AFCT-55) Dyes"
									priceLabels={AFCTPriceLabels}
								/>
							</Sticky>
							{AFCT && AFCT.length > 0 ? (
								AFCT.map((item) => {
									if (!searchId) {
										return (
											<TableRow
												{...item}
												key={item.id}
												priceLabels={AFCTPriceLabels}
												pdf_paths={pdf_paths}
											/>
										);
									} else {
										if (item.id === searchId) {
											return (
												<>
													<TableRow
														{...item}
														key={item.id}
														priceLabels={AFCTPriceLabels}
														pdf_paths={pdf_paths}
													/>
													<button
														className="button is-info is-small"
														onClick={onSearchClearClicked}
													>
														Clear Search Results
													</button>
												</>
											);
										}
										return null;
									}
								})
							) : (
								<progress className="progress is-small is-primary" max="100">
									15%
								</progress>
							)}
						</section>
					</div>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div className="">
					<div className="">
						<section className="">
							<Sticky enabled={true} top={100} innerZ="9999">
								<TableHeader
									title="Antifreeze Color Treatment (AFCT-55) Dyes"
									priceLabels={AFCTPriceLabels}
								/>
							</Sticky>
							{AFCT && AFCT.length > 0 ? (
								AFCT.map((item) => {
									if (!searchId) {
										return (
											<TableRow
												{...item}
												key={item.id}
												priceLabels={AFCTPriceLabels}
												pdf_paths={pdf_paths}
											/>
										);
									} else {
										if (item.id === searchId) {
											return (
												<>
													<TableRow
														{...item}
														key={item.id}
														priceLabels={AFCTPriceLabels}
													/>
													<button
														className="button is-info is-small"
														onClick={onSearchClearClicked}
													>
														Clear Search Results
													</button>
												</>
											);
										}
										return null;
									}
								})
							) : (
								<progress className="progress is-small is-primary" max="100">
									15%
								</progress>
							)}
						</section>
					</div>
				</div>
			</RES.Handheld>
		</>
	);
}
