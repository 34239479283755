import React from 'react';
import parse from 'html-react-parser';
import * as RES from '../../../../helpers/responsive';

export default function AFCTTableHeader({ title, priceLabels }) {
	// const rowHeight = { height: '70px' };

	const formatPriceLabel = (label) => {
		if (label) {
			let formattedLabel = '<div>';
			let linesArr = label.split('*');
			linesArr.forEach((line) => (formattedLabel += line + '<br/>'));
			formattedLabel += '</div>';
			return parse(formattedLabel);
		}
		return null;
	};

	return (
		<>
			<RES.Desktop>
				{' '}
				<div>
					<div className="columns title has-text-centered has-background-white">
						<div className="column">{title}</div>
					</div>
					<div className="columns has-background-white">
						<div className="column is-7">
							<div className="columns is-mobile is-size-7-mobile">
								
							</div>
						</div>

						<div className="column is-5">
							<div className="columns is-mobile is-size-7-mobile">
								<div className="column is-2 has-text-weight-bold">
								</div>
								<div className="column is-10 has-text-weight-bold has-text-centered">
									Price per Bottle
								</div>
							</div>
						</div>
					</div>
					<div className="columns has-background-white">
						<div className="column is-7">
							<div className="columns is-mobile is-size-7-mobile">
								<div className="column is-2 has-text-weight-bold">Shade</div>
								<div className="column is-4 has-text-weight-bold">
									Product Code
								</div>
								<div className="column is-4 has-text-weight-bold">
									Product Name
								</div>
								<div className="column is-2 has-text-weight-bold">SDS</div>
							</div>
						</div>

						<div className="column is-5">
							<div className="columns is-mobile is-size-7-mobile">
								<div className="column is-4 has-text-weight-bold">
									{formatPriceLabel(priceLabels.price1)}
								</div>
								<div className="column is-4 has-text-weight-bold">
									{formatPriceLabel(priceLabels.price2)}
								</div>
								<div className="column is-4 has-text-weight-bold">
									{formatPriceLabel(priceLabels.price3)}
								</div>
							</div>
						</div>
					</div>
					<div className="columns header-bottom-border has-background-link">
						<div className="column"></div>
					</div>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				{' '}
				<div className="has-background-primary has-text-white is-size-7 px-1">
					<div className="columns is-mobile">
						<div className="column is-size-5">{title}</div>
					</div>

					<div className="column">
						<div className="columns is-mobile">
							<div className="column is-3 has-text-weight-bold">Shade</div>
							<div className="column is-9">
								<div className="columns is-mobile">
									<div className="column is-3 has-text-weight-bold">
										Product Code
									</div>
									<div className="column is-6 has-text-weight-bold">
										Product Name
									</div>
									<div className="column is-3 has-text-weight-bold pl-5">
										Info
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</RES.Handheld>
		</>
	);
}
