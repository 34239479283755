import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import ConversionButton from '../../components/dbDisplay/dyes/conversionButton';

import GeneralTable from '../../components/dbDisplay/dyes/generalTable';

import chemistry_img from '../../images/dyes/acid/chemistry.jpg';
import scale_img from '../../images/dyes/acid/acidic-ph-scale.png';
import low_ph_symbol_img from '../../images/dyes/acid/low_pH_symbol.png';
import { useHistory } from 'react-router-dom';
import * as RES from '../../helpers/responsive';

export default function Acidic(props) {
	const [dyes, setDyes] = useState({});
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const pdf_paths = {
		tds: '/PDF Collection/2000-TDS/',
		sds: '/PDF Collection/SDS Files/2000-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/general');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					if (items[item].acid) {
						newState.push({
							id: item,
							sort_order: items[item].sort_order,
							partNo: items[item].RKIPartNo,
							name: items[item].name,
							descriptor: items[item].descriptor ? items[item].descriptor : '',
							lightShade: items[item].lightShade,
							darkShade: items[item].darkShade,
							physicalType: items[item].physicalType,
							price_1lb_Jar: items[item].price_1lb_Jar,
							price_5lb_Jar: items[item].price_5lb_Jar,
							price_25gal_Pail: items[item].price_25gal_Pail,
							price_small_drum: items[item].price_small_drum,
							price_large_drum: items[item].price_large_drum,
							price_tote: items[item].price_tote ? items[item].price_tote : 0,
							primaryColor: items[item].primaryColor,
							primaryColorOrder: items[item].primColorOrder,
							formerTradeNames: items[item].formerTradeNames,
							fluorescent: items[item].fluorescent
								? items[item].fluorescent
								: false,
							has_link: items[item].has_link ? items[item].has_link : false,
							link_url: items[item].link_url ? items[item].link_url : '',
							has_sds: items[item].has_sds ? items[item].has_sds : false,
							sds_filename: items[item].sds_filename
								? items[item].sds_filename
								: false,
							inactive: items[item].inactive ? items[item].inactive : false,
						});
					}
				}
				newState.sort((a, b) => (a.sort_order > b.primaryColor ? 1 : -1));
				setDyes(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>Koch Color - Dyes for Acidic Products</title>
				<meta
					name="description"
					content="The dyes on this list have demonstrated improved stability in alkaline solutions. It’s very difficult to predict the upper stability limit as this depends on several factors and not just the pH value alone. In general, these colors remain stable down to pH 2. A few colors have been used down to pH1. It is essential that thorough testing be completed on a sample basis before proceeding to use these dyes on a production level."
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box">
						<div className="title has-text-centered ">
							Dyes for Acidic (Low pH) Products (pH{'<'}4)
						</div>
						<ConversionButton />
						<section className="container">
							<div className="columns is-vcentered">
								<div className="column">
									<img className="acid" src={low_ph_symbol_img} alt="" />
									<img className="acid" src={scale_img} alt="" />
								</div>
								<div className="column is-1"></div>
								<div className="column is-6">
									<img className="" src={chemistry_img} alt="" />
								</div>
								{/* <div className="column is-5">
						</div> */}
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								The dyes on this list have demonstrated improved stability in
								alkaline solutions. It’s very difficult to predict the upper
								stability limit as this depends on several factors and not just
								the pH value alone. In general, these colors remain stable down
								to pH 2. A few colors have been used down to pH 1. It is
								essential that thorough testing be completed on a sample basis
								before proceeding to use these dyes on a production level.
							</div>
							{/* <div className="block is-size-4">Use Rates</div>
					<div className="content">
						Typical use rates in water-based liquid solutions:
						<ul className="">
							<li>
								Very Light Shade
								<ul>
									<li>1-lb per 25,000 gallons (4.8 ppm)</li>
								</ul>
							</li>
							<li>
								Light Shade
								<ul>
									<li>1-lb per 10,000 gallons (12 ppm)</li>
								</ul>
							</li>
							<li>
								Medium Shade
								<ul>
									<li>1-lb per 5,000 gallons (24 ppm)</li>
								</ul>
							</li>
							<li>
								Dark Shade
								<ul>
									<li>1-lb per 2,000 gallons (60 ppm)</li>
								</ul>
							</li>
							<li>
								Very Dark Shade
								<ul>
									<li>1-lb per 500 gallons (240 ppm)</li>
								</ul>
							</li>
						</ul>
					</div>
					<div className="block">
						*Note: ppm means parts per million. When adding dye to liquid, 1ppm
						is equivalent to 1 milligram per liter (mg/l)
					</div>
					<div className="block is-size-4">Case Packaging</div>
					<div className="block">
						NOTE: Dyes are also available in case packaging. For powder dyes,
						cases contain 12 x 1-lb jars. For liquid dyes, cases contain 12 x
						1-quart bottles or 4 x 1 gallon jugs. A discount of $8.00/lb off of
						the 1-lb powder price, and $1.00 off of the quart and gallon prices
						shown above is given. All discounts apply only for cases of the same
						color.
					</div>*/}
						</div>
					</div>

					<div className="box">
						<section className="section">
							<div className="bloc">
								<div className="block is-size-4">Powder Form</div>

								<div className="block has-text-weight-semibold">
									Inquire for pricing in quantities of 100 pounds and larger.
								</div>
							</div>
						</section>
					</div>
					<GeneralTable
						filteredDyes={dyes}
						// filterColors=
						onFilterClicked={() => {}}
						title="Dyes for Acidic (Low pH) product (pH<4)"
						displayPhysicalForm={true}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						noTDS={false}
					/>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<GeneralTable
					filteredDyes={dyes}
					// filterColors=
					onFilterClicked={() => {}}
					title="Dyes for Acidic (Low pH) product (pH<4)"
					displayPhysicalForm={true}
					onSearchClear={onSearchClearClicked}
					searchId={searchId}
					pdf_paths={pdf_paths}
					noTDS={false}
				/>
			</RES.Handheld>
		</>
	);
}
