import React, { useContext } from 'react';
import { PromoContext } from './PromoContext';
import './promo.scss'

export default function HeaderPromo(props) {
	const { promo, promoClicked } = useContext(PromoContext);

	return (
		<React.Fragment>
			{promo.promoTitle !== '' ? (
				<button className="button is-primary" onClick={promoClicked}>
					<div className='promo-title' dangerouslySetInnerHTML={{ __html: promo.promoTitle }} />
				</button>
			) : null}
		</React.Fragment>
	);
}
