import React from 'react';
import './hero.scss';
import coloryourworld from '../../images/coloryourworld.png';
import Promo from '../../containers/promo/headerPromo/headerPromo'

export default function Hero() {
	return (
		<section className="hero is-align-content-center">
			<div className="columns is-vcentered">
				<div className="column">
					<img className="" src={coloryourworld} alt="" />
				</div>
				<div className="column has-text-right">
					<Promo />
				</div>
				<div className="column is-1"></div>
			</div>
		</section>
	);
}
