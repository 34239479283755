import React, { useState, useEffect} from 'react';
import { HashLink } from 'react-router-hash-link';
import { Portal } from 'react-portal';
import firebase from '../../firebase/firebaseConfig';
import Fuse from 'fuse.js';

const fuseOptions = {
	// isCaseSensitive: false,
	includeScore: true,
	// shouldSort: true,
	// includeMatches: false,
	findAllMatches: true,
	minMatchCharLength: 2,
	// location: 0,
	threshold: 0.35,
	// distance: 100,
	// useExtendedSearch: true,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	keys: ['RKIPartNo', 'name', 'productType', 'shortPartNo', 'descriptor']
};

export default function SearchBar() {
	const [searchResults, setSearchResults] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [fuseSearch, setFuseSearch] = useState();

	useEffect(() => {
		const ref = firebase.database().ref('test/gen-index');
		ref.once('value', (snapshot) => {
			if (snapshot) {
				let items = snapshot.val();
				let newState = [];
				for (let item in items) {
					newState.push(items[item]);
				}
				setFuseSearch((data) => {
					return new Fuse(newState, fuseOptions);
				});
			}
		});
	}, []);

	const onChange = (e) => {
		setSearchResults([]);
		setSearchText(e.target.value);
		if (e.target.value !== '') {
			const results = fuseSearch.search(e.target.value);

			if (results.length > 0) {
				
				// get the result data by id from searchIndex
				let productsFound = results.map((item) => {
					return item.item
				})
				setSearchResults(productsFound)
			}
		}
	};

	const handleSearchResultClick = () => {
		setSearchText('');
	};

	const resultRow = (result) => {
		return (
			<HashLink
				to={result.url + '#' + result.dbid}
				key={result.id}
				onClick={handleSearchResultClick}
			>
				<div className="columns">
					<div className="column is-2">{result.RKIPartNo}</div>
					<div className="column is-3">{result.name}</div>
					<div className="column">
						<div>{result.productType}</div>
						{result.descriptor ? <div>{result.descriptor}</div> : ''}
					</div>
				</div>
			</HashLink>
		);
	};

	const closeSearchClicked = () => {
		setSearchText('');
	};

	return (
		<div className="field has-addons">
			<p className="control">
				<input
					className="input is-small"
					type="text"
					placeholder="Search..."
					onChange={onChange}
					value={searchText}
				/>
			</p>
			{searchText.length ? (
				<Portal
					node={document && document.getElementById('searchModalTarget')}
					id={1234}
				>
					<div className="search-overlay">
						<div className="columns has-background-primary has-text-light">
							{/* <div className="column is-2">Score</div> */}
							<div className="column is-2">Product Number</div>
							<div className="column is-3">Product Name</div>
							<div className="column is-4">Product Type/Application</div>
							<div className="column has-text-right">
								<button
									className="delete has-background-primary"
									onClick={closeSearchClicked}
								>
									X
								</button>
							</div>
						</div>
						<div className="columns has-background-primary has-text-light">
							<div className="column has-text-warning">
								Results: {searchResults.length}
							</div>
						</div>
						{searchResults && searchResults.length > 0 ? (
							searchResults.map((result) => {
								return resultRow(result);
							})
						) : (
							<p>no results</p>
						)}
					</div>
				</Portal>
			) : null}
		</div>
	);
}
