import React, { useState, useEffect } from 'react';
import { titleCase } from 'title-case';

import tds_icon from '../../../images/tds-icon.png';
import sds_icon from '../../../images/sds-icon.png';
import './tablerow.scss';

import * as RES from '../../../helpers/responsive';
import infoIcon from '../../../images/icon-info.png';

export default function SolventRow(props) {
	const [isLiquid, setIsLiquid] = useState(false);

	useEffect(() => {
		if (!props.powder) {
			setIsLiquid(true);
		}
	}, [props]);

	const createName = () => {
		const name = [];
		name.push(props.name);
		if (props.descriptor) {
			name.push(<div key="1">({props.descriptor})</div>);
		}
		return name;
	};

	const formerNames = () => {
		if (!props.formerTradeNames) {
			return <div>{createName()}</div>;
		} else {
			// if (props.formerTradeNames) {
			const oldnames = props.formerTradeNames.split('*');
			return (
				<div>
					<div>
						{createName()}
						<div className="is-size-7">
							Former Trade Names:
							<br />
						</div>
					</div>
					{oldnames.map((formerName, i) => {
						return (
							<div className="is-size-7" key={i}>
								{formerName}
								<br />
							</div>
						);
					})}
				</div>
			);
		}
	};

	const colorBox = (shade, handheld) => {
		if (shade !== '#FFFFFF') {
			return (
				<div
					className="colorBox is-size-7 has-text-centered"
					style={{ backgroundColor: shade }}
				>
					<br />
					{props.fluorescent && !handheld ? 'Fluorescent' : ''}
					{props.fluorescent && handheld ? 'F' : ''}
				</div>
			);
		}
		return (
			<div
				className="colorBox-bordered is-size-7 has-text-centered"
				style={{ backgroundColor: shade }}
			>
				<br />
				{props.fluorescent && !handheld ? 'Fluorescent' : ''}
				{props.fluorescent && handheld ? 'F' : ''}
			</div>
		);
	};

	const physicalFormText = () => {
		if (props.nps) {
			if (props.powder) {
				return <div className="column is-2 is-size-7-mobile">Powder</div>;
			} else {
				return <div className="column is-2 is-size-7-mobile">Liquid</div>;
			}
		}
		return (
			<div className="column is-2 is-size-7-mobile">
				{titleCase(props.physicalType.toLowerCase())}
			</div>
		);
	};

	const tooltipPricesTablet = () => {
		return (
			<>
				<div className="columns">
					<div className="column mt-3 has-text-weight-bold is-underlined">
						{props.name}
					</div>
				</div>
				{/* <div className="columns is-mobile is-size-7">
					<div className="column is-6">2-lb Can (liq)</div>
					<div className="column is-6">
						${Number(props.price_1lb_Jar).toFixed(2)}
					</div>
				</div> */}
				<div className="columns is-mobile is-size-7">
					<div className="column is-6">8-lb Can (liq)</div>
					<div className="column is-6">
						${Number(props.price_5lb_Jar).toFixed(2)}
					</div>
				</div>
				<div className="columns is-mobile is-size-7">
					<div className="column is-6">40-lb Pail (liq)</div>
					<div className="column is-6">
						${Number(props.price_25gal_Pail).toFixed(2)}
					</div>
				</div>

				<div className="columns is-mobile">
					{!props.noTDS ? (
						<div className="column is-4 is-offset-2">
							{props.has_link ? (
								<a
									href={
										process.env.PUBLIC_URL +
										props.pdf_paths.tds +
										props.link_url
									}
									target="_blank"
									rel="noreferrer"
								>
									<img src={tds_icon} alt="TDS Icon" />
								</a>
							) : (
								<div className="column is-6"></div>
							)}
						</div>
					) : null}
					{!props.noSDS ? (
						<div className="column is-4">
							{props.has_sds ? (
								<a
									href={
										process.env.PUBLIC_URL +
										props.pdf_paths.sds +
										props.sds_filename
									}
									target="_blank"
									rel="noreferrer"
								>
									<img src={sds_icon} alt="SDS Icon" />
								</a>
							) : (
								<div className="column is-6"></div>
							)}
						</div>
					) : null}
				</div>
			</>
		);
	};

	return (
		<>
			<RES.Desktop>
				<div
					id={props.id}
					className="columns is-centered is-mobile"
					style={{ marginTop: '20px' }}
					key={props.id}
				>
					<div className="column is-8">
						<div className="columns is-variable is-0-mobile is-1-tablet is-1-desktop is-mobile">
							<div className="column is-3">
								<div className="columns">
									<div className="column is-6">
										{colorBox(props.lightShade)}
									</div>
									<div className="column is-6">{colorBox(props.darkShade)}</div>
								</div>
							</div>
							<div className="column is-2 has-text-centered is-size-7-mobile">
								{props.partNo}
							</div>
							<div className="column is-3 is-size-7-mobile">
								{formerNames()}
							</div>
							{physicalFormText()}

							{!props.noTDS ? (
								<div className="column is-1">
									{props.has_link ? (
										<a
											href={
												process.env.PUBLIC_URL +
												props.pdf_paths.tds +
												props.link_url
											}
											target="_blank"
											rel="noreferrer"
										>
											<img src={tds_icon} alt="TDS Icon" />
										</a>
									) : (
										<div>N/A</div>
									)}
								</div>
							) : null}
							{!props.noSDS ? (
								<div className="column is-1">
									{props.has_sds ? (
										<a
											href={
												process.env.PUBLIC_URL +
												props.pdf_paths.sds +
												props.sds_filename
											}
											target="_blank"
											rel="noreferrer"
										>
											<img src={sds_icon} alt="SDS Icon" />
										</a>
									) : (
										<div>N/A</div>
									)}
								</div>
							) : null}
						</div>
					</div>
					<div className="column is-4">
						<div className="columns is-mobile">
							{/* <div
								className={`column is-4 is-size-7-mobile ${
									isLiquid && props.nps ? '' : ''
								}`}
							>
								${Number(props.price_1lb_Jar).toFixed(2)}
							</div> */}
							<div
								className={`column is-6 is-size-7-mobile ${
									isLiquid && props.nps ? '' : ''
								}`}
							>
								${Number(props.price_5lb_Jar).toFixed(2)}
							</div>
							<div
								className={`column is-6 is-size-7-mobile ${
									isLiquid && props.nps ? '' : ''
								}`}
							>
								${Number(props.price_25gal_Pail).toFixed(2)}
							</div>
						</div>
					</div>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div
					id={props.id}
					className="columns is-centered is-mobile is-size-7 px-1 "
					style={{ marginTop: '20px' }}
					key={props.id}
				>
					<div className="column">
						<div className="columns is-mobile">
							<div className="column is-3">
								<div className="columns is-mobile">
									<div className="column is-6">
										{colorBox(props.lightShade)}
									</div>
									<div className="column is-6">{colorBox(props.darkShade)}</div>
								</div>
							</div>
							<div className="column is-2 has-text-centered is-size-7-mobile">
								{props.partNo}
							</div>
							<div className="column is-3 is-size-7-mobile">
								{formerNames()}
							</div>
							{physicalFormText()}
							<div className="column is-1 priceTooltipTablet">
								<img className="mobile-info-icon" src={infoIcon} alt="info" />
								<span className="priceTooltipTablettext">
									{tooltipPricesTablet()}
								</span>
							</div>
						</div>
					</div>
				</div>
			</RES.Handheld>
		</>
	);
}
