import React, { useState, useContext, useEffect } from 'react';
import Hero from './Hero';
import Sticky from 'react-stickynode';
import Nav from './Nav';
import Footer from './Footer';
import './layout.scss';
import { PromoContext } from '../../containers/promo/headerPromo/PromoContext';
import * as RES from '../../helpers/responsive';

const Layout = (props) => {
	const [heroPromoModalActive, setHeroPromoModalActive] = useState(false);
	const { promo, promoClicked } = useContext(PromoContext);

	const handleHeroPromoClicked = () => {
		setHeroPromoModalActive(!heroPromoModalActive);
		promoClicked();
	};

	useEffect(() => {
		if (promo.clicked) {
			setHeroPromoModalActive(true);
		}
	}, [promo]);

	return !heroPromoModalActive ? (
		<div className="has-background-grey-light full">
			<RES.Desktop>
				<Hero onPromoClick={() => handleHeroPromoClicked()} />
			</RES.Desktop>
			<Sticky enabled={true} innerZ={999}>
				<Nav />
			</Sticky>
			<main>
				<div id="searchModalTarget" className=""></div>
				{props.children}
			</main>
			<Footer />
		</div>
	) : (
		<div className="modal is-active" onClick={handleHeroPromoClicked}>
			<div className="modal-background"></div>
			<div className="modal-background"></div>
			<div className="modal-card">
				<header className="modal-card-head has-background-link">
					<p
						className="modal-card-title has-text-white"
						dangerouslySetInnerHTML={{ __html: promo.promoTitle }}
					></p>
					<button className="delete" aria-label="close"></button>
				</header>
				<section className="modal-card-body">
					<p
						className="modal-card-content"
						dangerouslySetInnerHTML={{ __html: promo.promoText }}
					></p>
				</section>
				<footer className="modal-card-foot has-background-link"></footer>
			</div>
		</div>
	);
};

export default Layout;
