import React from 'react';

export default function GlycolsTableHeaderRow(props) {
	// const rowHeight = { height: '70px' };
	return (
		<div>
			<div className="columns is-centered has-background-white">
				<div className="column is-2 has-text-weight-bold">Part No.</div>
				<div className="column is-2 has-text-weight-bold">Product Name</div>
				<div className="column is-2 has-text-weight-bold">
					Gallon Jug
					<br />
					Price/Gal
				</div>
				<div className="column is-2 has-text-weight-bold">
					5-gal Pail
					<br />
					Price/Gal
				</div>
				<div className="column is-2 has-text-weight-bold">
					55-Gal Drum
					<br />
					Price/Gal
				</div>
			</div>
		</div>
	);
}
