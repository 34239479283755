import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import color_fan_img from '../images/carousel/color-fan.webp'
import color_lids_img from '../images/carousel/color-lids.webp'
import dyed_orchid_img from '../images/carousel/dyed-orchid.webp'
import dyed_spider_mum_img from '../images/carousel/dyed-spider-mum.webp'
import green_tubes_img from '../images/carousel/green_tubes.webp'
import color_tubes_img from '../images/carousel/color_tubes.webp'
export default class NC extends React.Component {
	render() {
		return (
			<Carousel
				interval="5000"
				transitionTime="2000"
				autoPlay={true}
				stopOnHover={false}
				infiniteLoop={true}
				showStatus={false}
				showThumbs={false}
			>
				<img src={dyed_spider_mum_img} alt="" />
				<img src={color_fan_img} alt="" />
				<img src={dyed_orchid_img} alt="" />
				<img src={color_lids_img} alt="" />
				<img src={green_tubes_img} alt="" />
				<img src={color_tubes_img} alt="" />
			</Carousel>
		);
	}
}