import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.scss';
import { HelmetProvider } from 'react-helmet-async';
import RenderRoutes from './routes/RenderRoutes';
import Layout from './components/layout/Layout';
import PromoContextProvider from './containers/promo/headerPromo/PromoContext';
import LanguageContextProvider from './context/LanguageContext';
// import SearchContextProvider from './containers/Search/SearchContext';

function App() {
	return (
		<HelmetProvider>
			<Router>
				<LanguageContextProvider>
					<PromoContextProvider>
						{/* <SearchContextProvider> */}
						<title>Koch Color</title>
						<meta
							name="description"
							content="Industrial dyes, pigments, glitter, and fragrances"
						/>
						<Layout>
							<Switch>
								<RenderRoutes />
							</Switch>
						</Layout>
						{/* </SearchContextProvider> */}
					</PromoContextProvider>
				</LanguageContextProvider>
			</Router>
		</HelmetProvider>
	);
}

export default App;
