import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import TableHeader from '../../components/dbDisplay/fragrances/fragranceHeaderRow';
import TableRow from '../../components/dbDisplay/fragrances/fragranceTableRow';
import Sticky from 'react-stickynode';
import food_img from '../../images/fragrances/food.jpeg';

export default function FoodFragrances() {
	const [fragrance, setFragrance] = useState({});

	const pdf_paths = {
		tds: '',
		sds: '/PDF Collection/SDS Files/Fragrance-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('fragrances');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					if (items[item].food) {
						newState.push({
							id: item,
							partNo: items[item].RKIPartNo,
							name: items[item].name,
							price_5_gallons: items[item].price_5_gallons,
							price_gallon: items[item].price_gallon,
							price_quart: items[item].price_quart,
							price_50_gallons: items[item].price_50_gallons,
							has_link: items[item].has_link ? items[item].has_link : false,
							link_url: items[item].link_url ? items[item].link_url : '',
							has_sds: items[item].has_sds ? items[item].has_sds : false,
							sds_filename: items[item].sds_filename
								? items[item].sds_filename
								: false,
						});
					}
				}
				setFragrance(newState);
			}
		});
	}, []);
	return (
		<div className="section fragrance-section">
			<Helmet>
				<title>Koch Color - Food Fragrances</title>
				<meta name="description" content="Koch Color - Food Fragrances" />
			</Helmet>
			<div className="box">
				<section>
					<img className="image is-128x128" src={food_img} alt="" />
				</section>
			</div>
			<div className="box">
				<section className="">
					<Sticky enabled={true} top={100}>
						<TableHeader title="Food Fragrances" />
					</Sticky>
					{fragrance && fragrance.length > 0 ? (
						fragrance.map((item) => {
							return <TableRow {...item} key={item.id} pdf_paths={pdf_paths} />;
						})
					) : (
						<div style={{ marginTop: '20px' }}>
							<progress className="progress is-small is-primary" max="100">
								15%
							</progress>
						</div>
					)}
				</section>
			</div>
		</div>
	);
}
