import React from 'react';
import TableHeader from './dyesHeaderRow';
import TableRow from './dyeRow';
import Sticky from 'react-stickynode';
import Filter from '../../../containers/dyes/Filters/DyesFilters';
// import PDFButton from '../PDFButton';
import PropTypes from 'prop-types'
import * as RES from '../../../helpers/responsive';

const GeneralTable = (props) => {

	const onFilterClicked = (value) => {
		props.onFilterClicked(value);
	};

	return (
		<>
			<RES.Desktop>
				<div className="box">
					<section>
						<Sticky enabled={true} top={110}>
							<div>
								{props.filterColors ? (
									<Filter
										onClick={props.filteredDyes ? onFilterClicked : null}
										total={props.filteredDyes.length}
										filterColors={props.filterColors}
										filterPhysicalForms={props.filterPhysicalForms}
										displayPhysicalForm={props.displayPhysicalForm}
									/>
								) : null}
								<TableHeader
									title={props.title}
									displayPhysicalForm={props.displayPhysicalForm}
									noLiquid={props.noLiquid}
									noTDS={props.noTDS}
									noPwdGran={props.noPwdGran}
									nps={props.nps}
									floralAbsorption={props.floralAbsorption}
								/>
							</div>
						</Sticky>
						{props.filteredDyes && props.filteredDyes.length > 0
							? props.filteredDyes.map((item) => {
									if (!props.searchId && !item.inactive) {
										return (
											<TableRow
												{...item}
												key={item.id}
												displayPhysicalForm={props.displayPhysicalForm}
												noTDS={props.noTDS}
												pdf_paths={props.pdf_paths}
												noLiquid={props.noLiquid}
												floralAbsorption={props.floralAbsorption}
											/>
										);
									} else {
										if (item.id === props.searchId) {
											return (
												<>
													<TableRow
														{...item}
														key={item.id}
														displayPhysicalForm={props.displayPhysicalForm}
														noTDS={props.noTDS}
														pdf_paths={props.pdf_paths}
														noLiquid={props.noLiquid}
														floralAbsorption={props.floralAbsorption}
													/>
													<button
														className="button is-info is-small"
														onClick={() => props.onSearchClear()}
													>
														Clear Search Results
													</button>
												</>
											);
										}
									}
									return null;
							  })
							: null}
					</section>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div className="">
					<section className="">
						<Sticky enabled={true} top={110} innerZ="9999">
							<div>
								{/* {props.filterColors ? (
									<Filter
										onClick={props.filteredDyes ? onFilterClicked : null}
										total={props.filteredDyes.length}
										filterColors={props.filterColors}
										filterPhysicalForms={props.filterPhysicalForms}
									/>
								) : null} */}
								<TableHeader
									title={props.title}
									displayPhysicalForm={props.displayPhysicalForm}
									noLiquid={props.noLiquid}
									noTDS={props.noTDS}
									noPwdGran={props.noPwdGran}
									nps={props.nps}
									floralAbsorption={props.floralAbsorption}
								/>
							</div>
						</Sticky>
						{props.filteredDyes && props.filteredDyes.length > 0
							? props.filteredDyes.map((item) => {
									if (!props.searchId && !item.inactive) {
										return (
											<TableRow
												{...item}
												key={item.id}
												displayPhysicalForm={props.displayPhysicalForm}
												noTDS={props.noTDS}
												pdf_paths={props.pdf_paths}
												noLiquid={props.noLiquid}
												floralAbsorption={props.floralAbsorption}
											/>
										);
									} else {
										if (item.id === props.searchId) {
											return (
												<>
													<TableRow
														{...item}
														key={item.id}
														displayPhysicalForm={props.displayPhysicalForm}
														noTDS={props.noTDS}
														pdf_paths={props.pdf_paths}
														noLiquid={props.noLiquid}
														floralAbsorption={props.floralAbsorption}
													/>
													<button
														className="button is-info is-small"
														onClick={() => props.onSearchClear()}
													>
														Clear Search Results
													</button>
												</>
											);
										}
									}
									return null;
							  })
							: null}
					</section>
				</div>
			</RES.Handheld>
		</>
	);
};


GeneralTable.propTypes = {
	displayPhysicalForm: PropTypes.bool.isRequired,
	noTDS: PropTypes.bool.isRequired
};
export default GeneralTable;