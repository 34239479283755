import React from 'react';
import parse from 'html-react-parser';
import infoIcon from '../../../../images/icon-info.png';
import * as RES from '../../../../helpers/responsive';
import sds_icon from '../../../../images/sds-icon1.png';

export default function AFCTTableRow(props) {

	const formatPriceLabel = (label) => {
		if (label) {
			let formattedLabel = '<div>';
			let linesArr = label.split('*');
			linesArr.forEach((line) => (formattedLabel += line + '<br/>'));
			formattedLabel += '</div>';
			return parse(formattedLabel);
		}
		return null;
	};
	const colorBox = (shade) => {
		if (shade !== '#FFFFFF') {
			return (
				<div
					className="colorBox is-size-7 has-text-centered"
					style={{ backgroundColor: shade }}
				>
					<br />
				</div>
			);
		}
		return (
			<div
				className="colorBox-bordered is-size-7 has-text-centered"
				style={{ backgroundColor: shade }}
			>
				<br />
			</div>
		);
	};

	const getSDSLink = () => {
		// !!Need to verify spanish is primary language and there is a spanish pdf before doing this!
			return process.env.PUBLIC_URL + props.pdf_paths.sds + props.sds_filename;
	};

	const tooltipPricesTablet = () => {
		return (
			<>
				<div className="columns">
					<div className="column mt-3 has-text-weight-bold is-underlined">
						{props.name}
					</div>
					<div className="column mt-3 has-text-weight-bold is-underlined">
						(price per bottle)
					</div>
				</div>
				<div className="columns is-mobile is-size-7">
					<div className="column is-6">
						{formatPriceLabel(props.priceLabels.price1)}
					</div>
					<div className="column is-6">${Number(props.price1).toFixed(2)}</div>
				</div>
				<div className="columns is-mobile is-size-7">
					<div className="column is-6">
						{formatPriceLabel(props.priceLabels.price2)}
					</div>
					<div className="column is-6">${Number(props.price2).toFixed(2)}</div>
				</div>
				<div className="columns is-mobile is-size-7">
					<div className="column is-6">
						{formatPriceLabel(props.priceLabels.price3)}
					</div>
					<div className="column is-6"> ${Number(props.price3).toFixed(2)}</div>
				</div>
				{props.has_sds ? (
					<div className="column is-4">
						{props.has_sds ? (
							<a href={getSDSLink()} target="_blank" rel="noreferrer">
								<img src={sds_icon} alt="SDS Icon" />
							</a>
						) : (
							<div className="column is-6"></div>
						)}
					</div>
				) : null}
			</>
		);
	};

	return (
		<>
			<RES.Desktop>
				<div
					id={props.id}
					className="columns is-centered has-background-white"
					style={{ marginTop: '20px' }}
				>
					<div className="column is-7">
						<div className="columns is-mobile is-size-7-mobile">
							<div className="column is-2">{colorBox(props.shade)}</div>
							<div className="column is-4">{props.partNo}</div>
							<div className="column is-4">{props.name}</div>
							<div className="column is-2">
								{props.has_sds ? (
									<a
										href={
											process.env.PUBLIC_URL +
											props.pdf_paths.sds +
											props.sds_filename
										}
										target="_blank"
										rel="noreferrer"
									>
										<img src={sds_icon} alt="SDS Icon" />
									</a>
								) : (
									<div>N/A</div>
								)}
							</div>
						</div>
					</div>
					<div className="column is-5">
						<div className="columns is-mobile is-size-7-mobile">
							<div className="column is-4">
								${Number(props.price1).toFixed(2)}
							</div>
							<div className="column is-4">
								${Number(props.price2).toFixed(2)}
							</div>
							<div className="column is-4">
								${Number(props.price3).toFixed(2)}
							</div>
						</div>
					</div>
					{/* <div className="columns header-bottom-border has-background-link-light">
					<div className="column"></div>
				</div> */}
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<div
					id={props.id}
					className="columns is-centered is-size-7 px-1 is-mobile"
				>
					<div className="column">
						<div className="columns is-mobile">
							<div className="column is-3">
										{colorBox(props.shade)}
							</div>
							<div className="column is-9">
								<div className="columns is-mobile">
									<div className="column is-3">{props.partNo}</div>
									<div className="column is-6">{props.name}</div>
									<div className="column is-3 priceTooltipTablet">
										<img
											className="mobile-info-icon"
											src={infoIcon}
											alt="info"
										/>
										<span className="priceTooltipTablettext">
											{tooltipPricesTablet()}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</RES.Handheld>
		</>
	);
}
