import React from 'react';

export default function PreservativesTableHeaderRow(props) {
	// const rowHeight = { height: '70px' };
	return (
		<div>
			<div className="title has-text-centered has-background-white mb-0">
				{props.title}
			</div>
			<div className="columns has-background-white">
				<div className="column is-2 has-text-weight-bold">Part No.</div>
				<div className="column is-2 has-text-weight-bold">Product Name</div>

				<div className="column  is-2 has-text-weight-bold">
					1-lb Jar
					<br />
					Price/lb
				</div>

				<div className="column is-2 has-text-weight-bold">
					5-lb Jar
					<br />
					Price/lb
				</div>
				<div className="column is-2 has-text-weight-bold">
					25-lb Pail
					<br />
					Price/lb
				</div>
				<div className="column is-2 has-text-weight-bold">
					55-lb Box
					<br />
					Price/lb
				</div>
			</div>
		</div>
	);
}
