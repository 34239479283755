import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.scss';
import SearchBar from '../../containers/Search/SearchBar';
import logo from '../../images/logo.png';

const Nav = () => {
	const [dyesDropdown, setDyesdropdown] = useState(false);
	// const [pigmentsDropdown, setPigmentsdropdown] = useState(false);
	const [auxDropdown, setAuxdropdown] = useState(false);
	const [fragranceDropdown, setFragrancedropdown] = useState(false);

	// Mobile Nav
	const [topBurgerActive, settopBurgerActive] = useState(false);
	const [burgerActive, setburgerActive] = useState(false);

	// Normal Nav
	const dropdownActive = 'navbar-item has-dropdown is-active';
	const dropdownInactive = 'navbar-item has-dropdown';

	const handleBurgerClick = () => {
		setburgerActive(!burgerActive);
	};
	const handleTopBurgerClick = () => {
		settopBurgerActive(!topBurgerActive);
	};

	const getBurgerState = () => {
		if (burgerActive) {
			return 'navbar-burger burger is-active';
		}
		return 'navbar-burger burger';
	};

	const getTopBurgerState = () => {
		if (topBurgerActive) {
			return 'navbar-burger burger is-active';
		}
		return 'navbar-burger burger';
	};

	const getDropdownState = () => {
		if (burgerActive) {
			return 'navbar-menu is-active';
		}
		return 'navbar-menu';
	};

	const getTopDropdownState = () => {
		if (topBurgerActive) {
			return 'navbar-menu is-active top';
		}
		return 'navbar-menu';
	};

	const getDyeHoverActive = () => {
		return dyesDropdown ? dropdownActive : dropdownInactive;
	};
	const dyeHover = (value) => {
		if (value) setDyesdropdown(true);
		else setDyesdropdown(false);
	};

	// const getPigmentsHoverActive = () => {
	// 	return pigmentsDropdown ? dropdownActive : dropdownInactive;
	// };

	// const pigmentsHover = (value) => {
	// 	if (value) setPigmentsdropdown(true);
	// 	else setPigmentsdropdown(false);
	// };

	const getFragrancesHoverActive = () => {
		return fragranceDropdown ? dropdownActive : dropdownInactive;
	};
	const fragranceHover = (value) => {
		if (value) setFragrancedropdown(true);
		else setFragrancedropdown(false);
	};

	const getAuxHoverActive = () => {
		return auxDropdown ? dropdownActive : dropdownInactive;
	};
	const auxHover = (value) => {
		if (value) setAuxdropdown(true);
		else setAuxdropdown(false);
	};

	return (
		<div>
			<nav
				className="navbar top"
				role="navigation"
				aria-label="main navigation"
			>
				<div className="navbar-brand">
					<div className="navbar-item has-text-white">
						ROBERT KOCH Industries Inc.
					</div>
					<NavLink
						to=""
						role="button"
						className={getTopBurgerState()}
						aria-label="menu"
						aria-expanded="false"
						onClick={handleTopBurgerClick}
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</NavLink>
				</div>

				<div id="navbarTop" className={getTopDropdownState()}>
					<div className="navbar-end">
						<a
							className="navbar-item top"
							href="http://secureweb.infotrac.net/msds/robertkoch"
							onClick={handleTopBurgerClick}
							target="_blank"
							rel="noreferrer"
						>
							Safety Data Sheets
						</a>
						<NavLink
							to="/contact"
							className="navbar-item top"
							activeClassName="is-active"
							onClick={handleTopBurgerClick}
						>
							Contact Us
						</NavLink>
						<div className="navbar-item top" id="searchBar">
							<SearchBar />
						</div>
					</div>
				</div>
			</nav>

			<nav className="navbar" role="navigation" aria-label="main navigation">
				<div className="navbar-brand">
					<NavLink to="" className="navbar-item" href="https://kochcolor.com">
						<img src={logo} alt="Koch Color"></img>
					</NavLink>

					<div
						// to="/dyes/general-use"
						role="button"
						className={getBurgerState()}
						aria-label="menu"
						aria-expanded="false"
						onClick={handleBurgerClick}
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</div>
				</div>

				<div className={getDropdownState()}>
					<div className="navbar-start">
						<NavLink to="/" className="navbar-item">
							Home
						</NavLink>

						<div
							className={getDyeHoverActive()}
							onMouseEnter={() => dyeHover(true)}
							onMouseLeave={() => dyeHover(false)}
						>
							<div to="" className="navbar-link">
								Dyes
							</div>
							<div className="navbar-dropdown">
								<div className="navbar-item has-text-weight-semibold is-size-5 has-background-grey-lighter">
									Industrial Applications - Water Soluble Dyes
								</div>
								<NavLink
									to="/dyes/general-use"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									General Use - Inks, Highlighters, Cleaning Products, (pH 4-9)
								</NavLink>
								{/* <div className="nested dropdown">
									<a className="navbar-item">
										<span className="icon-text ">
											<span>Engine Coolants {'>'}</span>
											<span className="icon">
												<i className="fas fa-chevron-right"></i>
											</span>
										</span>
									</a>

									<div className="dropdown-menu" id="dropdown-menu" role="menu">
										<div className="dropdown-content">
											<NavLink
												to="/dyes/coolant"
												className="navbar-item"
												onClick={handleBurgerClick}
											>
												Engine Coolant/Antifreeze & Heat Transfer Fluids
											</NavLink>
											<NavLink
												to="/dyes/afct"
												className="navbar-item"
												onClick={handleBurgerClick}
											>AFCT
											</NavLink>
										</div>
									</div>
								</div> */}
								<NavLink
									to="/dyes/coolant"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Engine Coolant/Antifreeze & Heat Transfer Fluids
								</NavLink>
								<NavLink
									to="/dyes/afct"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Engine Coolant/Antifreeze Color Treatment for Vehicle Service
									Shops
								</NavLink>

								<NavLink
									to="/dyes/wash"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Windshield Washer Fluid & Glass Cleaner
								</NavLink>
								<NavLink
									to="/dyes/alkaline"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Alkaline (High pH) products (pH{'>'}9)
								</NavLink>
								{/* <hr className="navbar-divider"></hr> */}
								<NavLink
									to="/dyes/acidic"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Acidic (Low pH) products (pH{'<'}4)
								</NavLink>
								<NavLink
									to="/dyes/tracer"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Tracer Dyes / Leak Detection
								</NavLink>
								{/* <NavLink
									to="/dyes/food-cosmetic"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Food & Cosmetic Grade Dyes for High Purity / Low Toxicity
									Applications
								</NavLink> */}
								<div className="navbar-item has-text-weight-semibold is-size-5 has-background-grey-lighter">
									Industrial Applications - Solvent Soluble Dyes
								</div>
								<NavLink
									to="/dyes/non-polar-solvents"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Hydrocarbons, Fuels, Oils, Waxes & Non-Polar Solvents
								</NavLink>
								{/* <NavLink
									to="/dyes/polar-solvents"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Polar Solvents and Alcohols
								</NavLink> */}
								<div className="navbar-item has-text-weight-semibold is-size-5 has-background-grey-lighter">
									Floral & Craft Applications
								</div>
								<NavLink
									to="/dyes/floral-and-craft-absorption"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Absorption (Systemic) Dyes for Fresh Flowers & Foliage
								</NavLink>
								<NavLink
									to="/dyes/floral-and-craft-immersion"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Immersion Dyes for Dried Flowers & Foliage
								</NavLink>
								<div className="navbar-item has-text-weight-semibold is-size-5 has-background-grey-lighter">
									Part No Conversions
								</div>
								<NavLink
									to="/dyes/conversions"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									4000-5000-6000 Product Code Conversions
								</NavLink>
							</div>
						</div>

						{/* <div
							className={getPigmentsHoverActive()}
							onMouseEnter={() => pigmentsHover(true)}
							onMouseLeave={() => pigmentsHover(false)}
						>
							<div to="" className="navbar-link">
								Pigments and Coatings
							</div>

							<div className="navbar-dropdown"> */}
						{/* <NavLink
									to="/pigments/standard-pigments"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Standard Pigments
								</NavLink> */}
						{/* <NavLink
									to="/pigments/metallic-pigments-paste"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Metallic Pigments Paste
								</NavLink>
								<NavLink
									to="/pigments/premixed-metallic"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Premixed Metallic Paints
								</NavLink> */}
						{/* <NavLink
									to="/pigments/moss-revive"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Moss Revive
								</NavLink> */}
						{/* </div>
						</div> */}

						<NavLink
							to="/moss-revive"
							className="navbar-item"
							onClick={handleBurgerClick}
						>
							Moss Revive
						</NavLink>

						{/* <NavLink
							to="/glitter"
							className="navbar-item"
							onClick={handleBurgerClick}
						>
							Glitter
						</NavLink> */}

						<div
							className={getFragrancesHoverActive()}
							onMouseEnter={() => fragranceHover(true)}
							onMouseLeave={() => fragranceHover(false)}
						>
							<div to="" className="navbar-link">
								Fragrances
							</div>
							<div className="navbar-dropdown">
								<NavLink
									to="/fragrances/all"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									All Fragrances (A-Z)
								</NavLink>
								<NavLink
									to="/fragrances/botanical"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Botanical
								</NavLink>
								<NavLink
									to="/fragrances/cinnamon-spice"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Cinnamon & Spice
								</NavLink>
								<NavLink
									to="/fragrances/citrus"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Citrus Collection
								</NavLink>
								<NavLink
									to="/fragrances/food"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Food Scents
								</NavLink>
								<NavLink
									to="/fragrances/fresh"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Fresh & Clean
								</NavLink>
								<NavLink
									to="/fragrances/fruit"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Fruit Scents
								</NavLink>
								<NavLink
									to="/fragrances/odor-masking"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Odor Masking
								</NavLink>
							</div>
						</div>
						{/* <NavLink
							to="/optical-brighteners"
							className="navbar-item"
							activeClassName="is-active"
						>
							Optical Brighteners
						</NavLink> */}

						<div
							className={getAuxHoverActive()}
							onMouseEnter={() => auxHover(true)}
							onMouseLeave={() => auxHover(false)}
						>
							<NavLink
								to="/auxilliary-products/defoamer"
								className="navbar-link"
								onClick={handleBurgerClick}
							>
								Auxiliary Products
							</NavLink>
							<div className="navbar-dropdown">
								{/* <NavLink
									to="/auxilliary-products/adhesive-binders"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Adhesive Binders
								</NavLink> */}
								<NavLink
									to="/auxilliary-products/defoamer"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Defoamer
								</NavLink>
								<NavLink
									to="/auxilliary-products/glycols"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Glycerin
								</NavLink>
								<NavLink
									to="/auxilliary-products/preservatives"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Preservatives
								</NavLink>
								<NavLink
									to="/auxilliary-products/surfactants"
									className="navbar-item"
									onClick={handleBurgerClick}
								>
									Surfactants
								</NavLink>
							</div>
						</div>

						<NavLink to="/dyes/conversions" className="navbar-item">
							Part No. Conversions
						</NavLink>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Nav;
