import React from 'react';
import { titleCase } from 'title-case';
import './tablerow.scss';

export default function DyeConversionTableRow(props) {

	const rowStyle = () => {
		if(props.backgroundColor) {
			return 'columns has-background-white';
		}
		else {
			return 'columns has-background-info-light';
		}

	}

	return (
		<div className={rowStyle()}>
			<div className="column is-1"></div>
			<div className="column is-2">{props.previousRKIPartNo}</div>
			<div className="column is-3">{titleCase(props.previousProductName.toLowerCase())}</div>
			<div className="column is-2">{props.newRKIPartNo}</div>
			<div className="column is-3">{titleCase(props.newRKIProductName.toLowerCase())}</div>
		</div>
	);
}
