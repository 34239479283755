import React from 'react'
import {Link} from 'react-router-dom'

export default function ConversionButton() {
	return (
		
		<div className="has-text-centered mb-4">
			<Link className="button is-link" to="/dyes/conversions">
				4000-5000-6000 Product Code Conversion Table
			</Link>
		</div>
	);
}
