import React from 'react';
import './footer.scss';
import logo from '../../images/logo.png';

export default function Footer() {

	const date = new Date()

	return (
		<footer className="footer">
			<div className="tile is-ancestor">
				<div className="tile is-3 is-parent">
					<article className="tile is-child">
						<p className="is-size-7 has-text-weight-bold">
							ROBERT KOCH Industries Inc.
						</p>
						<img src={logo} alt="" />
					</article>
				</div>
				<div className="tile is-parent">
					<article className="tile is-child">
						<p className="is-size-7 has-text-weight-semibold">
							Location <br />
							4770 N. Harback Rd. <br />
							Bennett, CO 80102 <br />
							USA
						</p>
					</article>
				</div>
				<div className="tile is-parent">
					<article className="tile is-child">
						<p className="is-size-7 has-text-weight-semibold">
							Phone: +1(303) 644-3763
							<br />
							Fax: +1 (303) 644-3045
							<br />
							Email:{' '}
							<a href="mailto: sales@kochcolor.com">sales@kochcolor.com</a>
						</p>
					</article>
				</div>
				<div className="tile is-parent">
					<article className="tile is-child">
						<p className="is-size-7 has-text-weight-semibold">
							Emergency Information Service
							<br />
							Infotrac (24 hours)
							<br />
							USA, Canada +1 (800) 535-5053
							<br />
							International +1 (352) 323-3500
						</p>
					</article>
				</div>
			</div>
			<div className="has-text-centered is-size-7 has-text-weight-semibold">
				©{date.getFullYear()} Robert Koch Industries All Rights Reserved
			</div>
		</footer>
	);
}
