import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import ConversionButton from '../../components/dbDisplay/dyes/conversionButton';
import GeneralTable from '../../components/dbDisplay/dyes/generalTable';

import blue_tracer from '../../images/dyes/tracer/bluelace-tracer.jpg';
import timelapse from '../../images/dyes/tracer/TimeElapse.jpg';
import timelapse1 from '../../images/dyes/tracer/TimeElapse1.jpg';
import wildfire from '../../images/dyes/tracer/wildfire.jpg';
import {
	TracerRatesPowder,
	TracerRatesLiquid,
} from '../../components/dbDisplay/dyes/tracerHelper/tracerRates';
import { useHistory } from 'react-router-dom';
import * as RES from '../../helpers/responsive';

export default function Tracer(props) {
	const [dyes, setDyes] = useState({});
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const pdf_paths = {
		tds: '/PDF Collection/2000-TDS/',
		sds: '/PDF Collection/SDS Files/2000-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/general');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					if (items[item].tracer) {
						newState.push({
							id: item,
							sort_order: items[item].sort_order,
							partNo: items[item].RKIPartNo,
							name: items[item].name,
							descriptor: items[item].descriptor ? items[item].descriptor : '',
							lightShade: items[item].lightShade,
							darkShade: items[item].darkShade,
							physicalType: items[item].physicalType,
							price_1lb_Jar: items[item].price_1lb_Jar,
							price_5lb_Jar: items[item].price_5lb_Jar,
							price_25gal_Pail: items[item].price_25gal_Pail,
							price_small_drum: items[item].price_small_drum,
							price_large_drum: items[item].price_large_drum,
							price_tote: items[item].price_tote ? items[item].price_tote : 0,
							primaryColor: items[item].primaryColor,
							primaryColorOrder: items[item].primColorOrder,
							formerTradeNames: items[item].formerTradeNames,
							fluorescent: items[item].fluorescent
								? items[item].fluorescent
								: false,
							has_link: items[item].has_link ? items[item].has_link : false,
							link_url: items[item].link_url ? items[item].link_url : '',
							has_sds: items[item].has_sds ? items[item].has_sds : false,
							sds_filename: items[item].sds_filename
								? items[item].sds_filename
								: false,
							inactive: items[item].inactive ? items[item].inactive : false,
						});
					}
				}
				newState.sort((a, b) => (a.sort_order > b.primaryColor ? 1 : -1));
				setDyes(newState);
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>Koch Color - Tracer Dyes / Leak Detection</title>
				<meta
					name="description"
					content="Tracer dyes are commonly used for tracing water leaks, irrigation lines, fertilizer systems, etc., and are generally used at the rate of 1-5 parts per million. The Rhodamine and Uranine dyes are very well suited for tracing since they are fluorescent and can be detected with a black light."
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box">
						<div className="title has-text-centered ">
							Tracer Dyes / Leak Detection
						</div>
						<ConversionButton />
						<section className="container">
							<div className="columns">
								<div className="column is-3">
									<img className="image ob" src={blue_tracer} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={timelapse} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={timelapse1} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={wildfire} alt="" />
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								Tracer dyes are commonly used for tracing water leaks,
								irrigation lines, fertilizer systems, etc., and are generally
								used at the rate of 1-5 parts per million. The Rhodamine and
								Uranine dyes are very well suited for tracing since they are
								fluorescent and can be detected with a black light.
							</div>
						</div>
					</div>

					<div className="box">
						<section className="block">
							<div className="block is-size-4">Tracer Dyes in Powder Form</div>
							<div className="column is-7 block">
								<TracerRatesPowder />
							</div>
							<div className="block">
								NOTE: Case packaging of 1-lb jars is available. Cases contain 12
								x 1-lb jars. A discount of $8.00/lb off of the 1-lb jar prices
								shown above is given. All discounts apply only for cases of the
								same color.
							</div>
						</section>
						<section>
							<div className="block is-size-4">Tracer Dyes in Liquid Form</div>
							<div className="column is-7 block">
								<TracerRatesLiquid />
							</div>
							<div className="block">
								NOTE: Case packaging of liquid dyes is available. We offer quart
								bottles (2-lbs each) packaged 12 bottles per case and gallon
								jugs (9-lbs each) packaged 4 jugs per case. A discount of
								$1.00/lb off of the quart and gallon prices shown above is
								given. All discounts apply only for cases of the same color.
							</div>
						</section>
					</div>
					<GeneralTable
						filteredDyes={dyes}
						// filterColors=
						onFilterClicked={() => {}}
						title="Tracer Dyes / Leak Detection"
						displayPhysicalForm={true}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						noTDS={false}
					/>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<GeneralTable
					filteredDyes={dyes}
					// filterColors=
					onFilterClicked={() => {}}
					title="Tracer Dyes / Leak Detection"
					displayPhysicalForm={true}
					onSearchClear={onSearchClearClicked}
					searchId={searchId}
					pdf_paths={pdf_paths}
					noTDS={false}
				/>
			</RES.Handheld>
		</>
	);
}
