import React, { createContext, useState, useEffect } from 'react';
import firebase from '../../../firebase/firebaseConfig';

export const PromoContext = createContext();

const PromoContextProvider = (props) => {
	const [promo, setPromo] = useState({
		promoTitle: '',
		promoText: '',
		clicked: false,
	});

	useEffect((promo) => {
		const ref = firebase.database().ref('promo');
		ref.on('value', (snapshot) => {
			let items = snapshot.val();
			let newState = {};
			for (let item in items) {
				if (item === 'headerPromo') {
					newState.promoTitle = items[item].titleText;
					newState.promoText = items[item].text;
					newState.clicked = false
				}
			}
			setPromo({ ...promo, ...newState });
		});
	}, []);

	const promoClicked = () => {
		const clicked = promo.clicked
		setPromo({ ...promo, clicked: !clicked });
	};

	return (
		<PromoContext.Provider value={{ promo, promoClicked }}>
			{props.children}
		</PromoContext.Provider>
	);
};

export default PromoContextProvider;
