import React from 'react';
// import './tablerow.scss'

export default function GlycolsTableRow(props) {
	const checkPriceInquire = (price) => {
		return price === '0.00' ? 'Inquire' : '$' + price;
	};

	return (
		<React.Fragment>
			<div className="columns is-centered" style={{ marginTop: '20px' }}>
				<div className="column is-2">{props.RKIPartNo}</div>
				<div className="column is-2">{props.name}</div>
				<div className="column is-2">
					{/* ${Number(props.price_gal_jug).toFixed(2)} */}
					{checkPriceInquire(Number(props.price_gal_jug).toFixed(2))}
				</div>
				<div className="column is-2">
					{/* ${Number(props.price_5_gal_pail).toFixed(2)} */}
					{checkPriceInquire(Number(props.price_5_gal_pail).toFixed(2))}
				</div>
				<div className="column is-2">
					Inquire
					{/* ${Number(props.price_55_gal_drum).toFixed(2)} */}
					{/* {checkPriceInquire(Number(props.price_55_gal_drum).toFixed(2))} */}
				</div>
			</div>
		</React.Fragment>
	);
}
