import React from 'react';

export function TracerRatesPowder() {
	return (
		<div className="card">
			<header className="card-header">
				<p className="card-header-title">
					Typical use rates and helpful conversions
				</p>
			</header>
			<div className="card-content">
				<div className="content">
					<div className="columns">
						<div className="column">
							<div className="is-size-5">Low Visible Detection</div>
							<div>1 part per million (ppm)</div>
							<div>1 milligram per liter (mg/l)</div>
							<div>8.3 x 10-6 pounds per gallon</div>
							<div>1 pound per 120,000 gallons</div>
							<div>2.7 pounds per acre foot</div>
						</div>
						<div className="column">
							<div className="is-size-5">Medium Visible Detection</div>
							<div>5 part per million (ppm)</div>
							<div>5 milligram per liter (mg/l)</div>
							<div>4.15 x 10-5 pounds per gallon</div>
							<div>1 pound per 24,000 gallons</div>
							<div>13.50 pounds per acre foot</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function TracerRatesLiquid() {
	return (
		<div className="card">
			<header className="card-header">
				<p className="card-header-title">
					Typical use rates and helpful conversions
				</p>
			</header>
			<div className="card-content">
				<div className="content">
					<div className="columns">
						<div className="column">
							<div className="is-size-5">Low Visible Detection</div>
							<div>3 part per million (ppm)</div>
							<div>3 milligram per liter (mg/l)</div>
							<div>2.5 x 10-5 pounds per gallon</div>
							<div>1 pound per 40,000 gallons</div>
							<div>1 gallon per acre foot</div>
						</div>
						<div className="column">
							<div className="is-size-5">Medium Visible Detection</div>
							<div>15 part per million (ppm)</div>
							<div>15 milligram per liter (mg/l)</div>
							<div>12.5 x 10-4 pounds per gallon</div>
							<div>1 pound per 8,000 gallons</div>
							<div>5 pounds per acre foot</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
