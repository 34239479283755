import React, { useEffect, useState } from 'react';

export default function DyesConversionFilter(props) {
	const [filter, setFilter] = useState(props.filterCodes);

	// triggers the filtercollection and parent callback on
	useEffect(() => {
		const filterKeys = [];
		for (let codeKey in filter) {
			if (filter[codeKey]) filterKeys.push(codeKey);
		}
		props.onClick(filterKeys);
	}, [filter]);

	const onChange = (e) => {
		const toggle = !filter[e.target.name];
		setFilter({ ...filter, [e.target.name]: toggle });
	};

	const generateFilterDisplay = () => {
		let colorList = [];
		for (var key in filter) {
			colorList.push(
				<div key={key}>
					{/* <div className="field">
						<div className="control"> */}
					<label className="checkbox">
						<input
							type="checkbox"
							name={key}
							onChange={onChange}
							checked={filter[key]}
						/>
						{' ' + key + '000 series'}
					</label>
				</div>
				// 	</div>
				// </div>
			);
		}
		return colorList;
	};

	return (
		<article className="panel is-info">
			<p className="panel-heading is-size-6 mb-2">Product Code Filter</p>
			<div className="columns mb-0">
				<div className="column is-offset-1">
					{generateFilterDisplay()}
					<div className="field-body"></div>
				</div>
			</div>

			<div className="pt-2 pb-2 has-text-centered has-background-link has-text-white has-text-weight-semibold">
				Total: [{props.total}]
			</div>
		</article>
	);
}
