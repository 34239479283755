import React from 'react';

import { Helmet } from 'react-helmet-async';
import { ContactForm } from './ContactForm';

export default function Contact() {
	return (
		<div className="section">
			<Helmet>
				<title>Koch Color - Contact Form</title>
				<meta
					name="description"
					content="contact form"
				/>
			</Helmet>
			{/* <div>

			sales@kochcolor.com
			</div> */}
			<div className="columns is-flex">
				<div className="column is-8 is-offset-2 has-background-link">
					<ContactForm />
				</div>
			</div>
		</div>
	);
}
